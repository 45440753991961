.storage-folder {
  .folder-title {
    color: #666;
    padding: 8px 6px;
  }
  .img-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    .img-block {
      width: 160px;
      height: 90px;
      border: 1px solid #333;
      margin: 6px;
      position: relative;
      transition: 0.3s all ease-in-out;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        transition: 0.3s all ease-in-out;
      }
      .overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 20px;
        opacity: 0;
        background-color: rgba(0, 0, 0, 0.8);
        transition: 0.3s opacity ease-in-out;
        &:hover {
          opacity: 1;
        }
      }
      .action {
        color: #aaa;
        cursor: pointer;
        transition: 0.3s color ease-in-out;
        &:hover {
          color: #fff;
        }
      }
    }
  }
  .page-controller {
    padding-bottom: 0;
  }
}
