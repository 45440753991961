html {
  overflow-y: scroll;
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 10px rgba(174, 117, 228, 0.664);
    background-color: transparent;
  }
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $subPurple;
  }
}

:root {
  overflow-y: auto;
  overflow-x: hidden;
}
:root body {
  position: absolute;
}

body {
  font-family: 'Roboto', 'Noto Sans TC', sans-serif;
  width: 100%;
  //  100vw 為視窗的左邊緣到右邊緣，寬度會因為卷軸的關係導致寬度過寬，而讓畫面出現橫向卷軸
  // width: 100vw;
  overflow: hidden;
  padding-right: 0 !important;
  // 新版navbar
  margin-top: $navHeight;
  background-color: $subLightColor;
  @include mobile {
    margin-top: $navHeight;
  }
}

.mainContainer {
  width: $mainContainerWidth;
  @include flex_func(center, center, column);
  .mainBody {
    width: $bodyContainerWidth;
  }
}
.main-content {
  min-height: $minMainContentHeight;
}
.secondary-content {
  min-height: 65vh;
}
#footer {
  height: $footerHeight;
  line-height: 1.5;
}
#page-loader-container {
  height: 100vh;
  width: 100vw;
  @include flex_func();
  > div {
    width: 120px;
    height: 120px;
  }
}
.fw-bolder {
  font-weight: bolder;
}
.fw-bold {
  font-weight: bold;
}
// footer {
//   text-align: center;
//   border-top: 1px solid #ccc;
// }
.MuiFormHelperText-root.Mui-error {
  color: red !important;
}
.dataset-list {
  .fieldset {
    margin-left: auto;
    margin-right: auto;
    & + .fieldset {
      margin-top: 36px;
    }
  }
  .title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    &::before {
      width: 10px;
      height: 20px;
      background-color: $mainColor;
      margin-right: 5px;
      content: '';
    }
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 16px;
  }
  .properties {
    font-size: 16px;
    padding-left: 15px;
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    .property {
      display: flex;
      column-gap: 12px;
      min-height: 24px;
      .property-name {
        width: 120px;
        display: flex;
        align-items: center;
        font-weight: bold;
      }
      .property-value {
        display: flex;
        align-items: center;
        gap: 8px;
      }
    }
  }
}

.custom-link-button {
  @include customButton;
  padding: 0;
  a {
    display: block;
    text-decoration: none;
    color: #fff;
    padding: 10px;
  }
}

.swal-modal.device-binding-hint .swal-text {
  text-align: left;
  line-height: 2;
}
