.point-transfer,
.group-info {
  width: 80%;
  margin: 0 auto;
  padding: 60px 0;
  .points-container {
    width: 100%;
    border-radius: 10px;
    background-color: #fff;
    padding: 20px 30px;
    box-shadow: 3px 3px 5px rgba(102, 102, 102, 0.2);
    .topic {
      padding-left: 10px;
      border-left: 10px solid $mainColor;
      font-size: 1.25rem;
      margin-bottom: 20px;
      color: $mainColor;
      font-weight: bold;
    }
    .fieldset {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      color: $subColor;
      .title {
        min-width: 60px;
        font-weight: bold;
      }
    }
    .buy-point-btn {
      @include customButton;
      margin: 0;
      width: 60%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: auto;
      background-color: #905aef;
      box-shadow: 3px 3px 0 #905aef;
      padding: 5px;
      font-size: 1rem;
      border-color: lighten(#905aef, 20%);
      // border-bottom-color: $subPurple;
      // border-right-color: $subPurple;
      svg {
        width: 18px;
        height: 18px;
        fill: #fff;
        margin-right: 10px;
      }
      &:hover {
        background-color: darken(#905aef, 10%);
      }
    }
  }
  .transfer {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    .input-container {
      width: 100%;
    }
    .points-container {
      height: 100%;
      display: flex;
      flex-direction: column;

      &__input-group {
        display: flex;
        align-items: center;
      }
      .sub-topic {
        display: flex;
        margin-bottom: 10px;
        p {
          width: 50%;
          font-size: 14px;
          color: $mainColor;
        }
      }
      .fieldset {
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
        .title {
          min-width: 40px;
          margin-bottom: 10px;
        }
        .properties {
          width: 100%;
        }
      }
    }
    .transfer-btn {
      @include customButton;
      width: 100%;
      padding: 5px 0;
      font-size: 1rem;
      background-color: $subPurple;
      margin-top: auto;
      box-shadow: 3px 3px 0 $subPurple;
      &:hover {
        background-color: lighten($subPurple, 10%);
      }
    }
  }
  @include pad {
    width: 80%;
  }
  @include mobile {
    width: 100%;
    padding: 60px 10px;
    .row {
      padding: 0 15px;
    }
    .transfer {
      margin-bottom: 20px;
      padding: 0;
    }
    .points-container {
      padding: 20px;
      .buy-point-btn {
        width: 100%;
      }
    }
    .MTableToolbar-searchField-14 {
      min-width: none;
      padding-left: 0;
    }
  }
}
