.headerContainer {
  background-repeat: no-repeat;
  // background-size: cover;
  // background-position: left;
  // background-color: #4090e0;
  height: 80vh;
  width: 100%;
  @include flex_func(center);
  .headerMiddle {
    width: $bodyContainerWidth;
    @include flex_func(center, flex-start, column);
  }

  #container {
    font-size: 30px;
    // color: white;
    span {
      margin: 0 20px 0 0;
      word-break: break-all;
    }
    i {
      font-style: normal;
    }
    button {
      width: 100px;
      margin: 8% 5% 0 0;
      font-size: 20px;
    }
  }
}
