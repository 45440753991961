.loading-page-container {
  width: 100%;
  .header {
    aspect-ratio: 16/9;
  }
  .title {
    height: 3.6rem;
    margin: 40px auto;
  }
  .sub-title {
    height: 1.8rem;
    margin: 24px auto;
  }
  .home-video {
    padding-bottom: 60px;
    .title {
      width: 34rem;
    }
    .sub-title {
      width: 34rem;
      @media (max-width: 525px) {
        height: 3.6rem;
      }
    }
    &__video {
      padding-top: 56.25;
      aspect-ratio: 16/9;
    }
    @media (max-width: 767px) {
      .title,
      .sub-title {
        width: 100%;
      }
    }
  }
  .product-feature {
    .title {
      width: 45rem;
      @media (max-width: 991px) {
        width: 100%;
      }
    }
    .sub-title {
      width: 27rem;
      @media (max-width: 450px) {
        height: 1.4rem;
        width: 20rem;
      }
    }
    &__feature-container {
      display: flex;
      flex-wrap: wrap;
      padding-bottom: 60px;
      justify-content: space-around;
      &__feature {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 20px;
        &__img {
          width: 90%;
          aspect-ratio: 1;
          @media (max-width: 767px) {
            width: 60%;
          }
        }
        &__title,
        &__content {
          height: 3rem;
          padding: 0px 20px;
          margin: 10px 0;
        }
        &__title {
          width: 12rem;
        }
        @media (max-width: 767px) {
          margin-bottom: 40px;
        }
      }
    }
  }
  .product-application {
    .title {
      width: 22rem;
    }
    &__product-container {
      display: flex;
      flex-wrap: wrap;
      padding-bottom: 60px;
      justify-content: space-around;
      &__product {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 20px;
        &__img {
          width: 90%;
          aspect-ratio: 1;
          @media (max-width: 767px) {
            width: 60%;
          }
        }
        &__name,
        &__title,
        &__sub-title,
        &__content {
          height: 2.5rem;
          padding: 0px 20px;
          margin: 10px 0;
        }
        &__name,
        &__title {
          height: 3rem;
          width: 15rem;
        }
        &__button {
          width: 150px;
          height: 37px;
        }
        @media (max-width: 767px) {
          margin-bottom: 40px;
        }
      }
    }
  }
}
