.navbar.front-menu {
  width: 100%;
  background-color: rgba(240, 237, 245, 1);
  font-size: 14px;
  padding: 0 10px;
  color: $mainColor;
  position: fixed;
  top: -50px;
  transition: top 0.3s ease-in-out;
  .navbar-nav {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }
  .nav-logo {
    margin-right: 10px;
    img {
      height: 37px;
    }
  }
  .login-status-btn {
    .login-btn.btn-outline-secondary {
      width: 55px;
      background-color: lighten($subColor, 10%);
      color: #fff;
      &:hover {
        background-color: lighten($subColor, 20%) !important;
      }
      &:focus {
        box-shadow: none;
      }
    }
    .sign-up-btn.btn-primary {
      width: 55px;
      background-color: $mainColor !important;
      border-color: transparent;
      color: #fff !important;
      &:hover {
        background-color: lighten($mainColor, 20%) !important;
      }
      &:active {
        background-color: darken($subPurple, 10%) !important;
      }
      &:focus {
        box-shadow: none;
      }
    }
  }
  a.nav-link {
    width: 100%;
    display: block;
    text-align: center;
    &:hover {
      color: lighten($mainColor, 5%) !important;
    }
  }
  a.nav-link.active {
    color: $mainColor !important;
  }
  .login-status-btn {
    align-items: center;
    .nav-item {
      a {
        padding: 0;
      }
    }
  }
  .navbar-collapse {
    justify-content: space-between;
  }

  .btn-primary:not(:disabled):not(.disabled).active,
  .btn-primary:not(:disabled):not(.disabled):active,
  .show > .btn-primary.dropdown-toggle {
    background-color: transparent;
    border-color: transparent;
    color: $mainColor;
    &:focus {
      box-shadow: none;
    }
  }
  .use-platform-dropdown {
    margin-left: 10px;
    .dropdown-menu {
      width: 200px;
      max-height: 200px;
      overflow-y: scroll;
      padding: 0;
      .dropdown-item {
        font-size: 14px;
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: center;
        padding: 12px 24px;
      }
    }
  }
  .use-platform-btn {
    font-size: 14px;
    color: #fefeff;
    background-color: $subColor;
    border-color: unset;
    border-radius: 5px;
    &:active {
      background-color: $mainColor;
      color: #fff;
      box-shadow: unset;
      border-color: transparent;
    }
    &:hover {
      background-color: $mainColor;
      color: #fefeff;
    }
    &:focus {
      background-color: $mainColor;
      color: #fff;
      box-shadow: unset;
      border-color: transparent;
    }
  }
  .use-platform-btn.btn-primary.dropdown-toggle {
    &:focus,
    &:active {
      background-color: $mainColor;
      color: #fff;
    }
  }
  .switch-lang {
    margin-right: 20px;
  }
  .nav-item .dropdown {
    a {
      font-size: 14px;
    }
    .dropdown-toggle.nav-link {
      padding-right: 0;
      &:hover,
      &:active {
        color: $mainColor;
      }
    }
    .dropdown-toggle::after {
      margin-left: 0.1rem;
    }
  }
  .nav-item button {
    font-size: 14px;
  }
  .dropdown-menu {
    padding: 0;
    .dropdown-item {
      padding: 12px 20px;
    }
  }
  .dropdown-divider {
    margin: 0;
  }
  a {
    text-align: center;
    text-decoration: none;
    color: rgba(0, 0, 0, 0.5);
    padding: 0 12px;
    &:hover {
      color: $mainColor;
    }
  }
  .navbar-brand {
    margin-right: 0;
    a {
      padding: 0;
    }
  }
  a.active {
    color: $mainColor;
  }
  .navbar-toggler.collapsed {
    border-color: transparent;
  }
  .custom-dropdown {
    text-align: center;
    margin: 0 10px;
    padding: 10px 0;
    .dropdown-toggle::after {
      border-top: 0.4em solid;
      border-right: 0.4em solid transparent;
      border-left: 0.4em solid transparent;
    }
    .dropdown-toggle {
      background-color: transparent;
      border-radius: 0;
      color: rgba(0, 0, 0, 0.5);
      border: unset;
      font-size: 14px;
      padding: 0;
      &:focus {
        box-shadow: unset;
      }
      &:active {
        color: $mainColor;
      }
      a {
        padding: 0;
        margin: 0;
      }
    }
    .dropdown-menu {
      padding: 0;
      top: 100%;
      border: unset;
      border-radius: 0;
      background-color: rgba(240, 237, 245, 1);
      padding-top: 4.5px;
      box-shadow: 1px 3px 3px rgba(53, 53, 53, 0.4);
    }
    .dropdown-item {
      font-size: 14px;
      text-align: left;
      &:hover {
        background-color: lighten($mainColor, 40%);
        a {
          color: $mainColor;
        }
      }
      &:active {
        background-color: lighten($mainColor, 40%);
      }
    }
  }
  .classes-btn {
    width: 100px;
    padding: 6px 12px;
    margin-right: 20px;
  }
  .dropdown {
    .dropdown-item.active,
    .dropdown-item:active {
      background-color: lighten($mainColor, 40%);
    }
  }
  @include laptop {
    width: 100%;
    background-color: rgba(240, 237, 245, 1);
    .use-platform-dropdown {
      margin-left: 0;
    }
    .navbar-nav {
      .nav-link {
        text-align: center;
        padding: 10px 0;
      }
      .dropdown {
        width: 100%;
        margin: 0 auto;
        text-align: center;
      }
      .dropdown-menu {
        width: 100%;
        max-height: 150px;
        border-radius: 0;
        border: unset;
        background-color: transparent;
        padding: 0;
        overflow-x: hidden;
      }
      .use-platform-btn.dropdown-toggle {
        width: 100%;
        margin: 10px 0;
        color: #fff;
        background-color: $mainColor;
      }
      .dropdown-toggle::after {
        margin-left: 10px;
      }
      .nav-item {
        width: 100%;
        a {
          margin: 0;
          padding: 10px 0;
        }
      }
      .dropdown-item a {
        text-align: center;
        padding: 0;
        margin-right: 10px;
      }
      .switch-lang.nav-item {
        margin-right: 0;
        &__btn {
          width: 100%;
        }
      }
      a,
      .dropdown-toggle {
        color: lighten($subColor, 10%);
        margin-right: 0;
      }
    }
    .nav-item .dropdown {
      a {
        justify-content: center;
      }
    }

    .custom-dropdown {
      text-align: left;
      padding: 10px;
      .dropdown-toggle::after {
        border-top: 0.4em solid;
        border-right: 0.4em solid transparent;
        border-left: 0.4em solid transparent;
      }
      .dropdown-toggle {
        background-color: transparent;
        border-radius: 0;
        border: unset;
        font-size: 14px;
        padding: 0;
        &:hover {
          color: $mainColor;
        }
        &:focus {
          box-shadow: unset;
        }
        &:active {
          color: $mainColor;
        }
        a {
          padding: 0;
          margin: 0;
        }
      }
      .dropdown-menu {
        top: 100%;
        background-color: transparent;
        box-shadow: none;
      }
      .dropdown-item {
        a {
          width: 100%;
          color: lighten($subColor, 30%);
        }
        &:hover {
          background-color: lighten($mainColor, 40%);
        }
        &:active {
          background-color: lighten($mainColor, 40%);
        }
      }
    }
    .custom-dropdown.show {
      width: 100%;
      .dropdown-toggle {
        width: 100%;
        padding: 10px;
        background-color: lighten($mainColor, 40%);
        font-weight: 700;
      }
    }
    .custom-dropdown.show.dropdown {
      padding: 0;
    }
    a {
      margin-right: 1rem;
    }
    a.active {
      width: 100%;
      color: $mainColor;
    }
    .login-status-btn {
      margin: 10px 0;
      .login-btn.btn-outline-secondary,
      .sign-up-btn.btn-primary {
        width: 100px;
      }
    }
    .nav-link.classes-btn {
      width: 100%;
      margin: 0;
      a {
        margin: 0;
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
  @include mobile {
    position: fixed;
    top: 0;
    .navbar-nav {
      .use-platform-btn {
        width: 100%;
      }
      .dropdown,
      .dropdown-toggle,
      .dropdown-menu,
      .dropdown-item {
        width: 100%;
      }
    }
    .dropdown-item {
      a {
        margin-left: 0;
      }
    }
    a.classes-btn {
      margin: 10px 0;
      margin-right: 1rem;
    }
  }
}
.navbar.scroll-top-fixed {
  top: 0;
}
.navbar {
  width: 100%;
  background-color: rgba(240, 237, 245, 1);
  font-size: 14px;
  padding: 0 10px;
  color: $mainColor;
  a.nav-link.active {
    color: $mainColor !important;
  }
}
