.term-page {
  padding: 30px 10px;
  line-height: 1.5;
  h2 {
    font-weight: bold;
    font-size: 1.8rem;
    text-align: center;
    margin-bottom: 1rem;
  }
  h3 {
    font-weight: 600;
    font-size: 1.5rem;
    color: $subColor;
    &::before {
      content: '■';
      margin-right: 0.5rem;
    }
  }
  h4 {
    font-size: 1.2rem;
    margin: 0.5rem 0;
    display: inline-block;
    padding: 0 0.5rem;
    border: 1px solid $subColor;
  }
  ol {
    margin-bottom: 1.2rem;
    list-style: decimal;
    list-style-position: inside;
  }
  ol > li > ol {
    padding-top: 0.5rem;
    padding-left: 1rem;
    list-style-position: inside !important;
    list-style: lower-alpha;
  }
  li {
    margin-bottom: 0.5rem;
    color: $subColor;
  }
  ul {
    list-style: disc;
    list-style-position: inside;
  }
  p {
    margin: 0.5rem 0;
  }
}
