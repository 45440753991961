.use-case-main {
  white-space: pre-wrap;
  &__banner {
    width: 100%;
    padding: 100px 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    &__title {
      width: 100%;
      font-size: 2rem;
      font-weight: 700;
      color: #fff;
      line-height: 1.5;
    }
    &__button {
      @include customButton;
      margin: 0;
      margin-top: 20px;
    }
  }
  &__analyse,
  &__use-range {
    &__title {
      text-align: center;
      margin: 30px 0;
      font-size: 1.8rem;
      font-weight: 700;
      color: $mainColor;
    }
    .case-card-container {
      .case-card {
        align-items: center;
        &::after {
          content: '';
          width: 100%;
          height: 3px;
          margin: 40px 0;
          background-image: linear-gradient(
            to right,
            #ccc 0%,
            #ccc 50%,
            transparent 50%
          );
          background-size: 20px 3px;
          background-repeat: repeat-x;
        }
        img {
          width: 100%;
          height: 250px;
          object-fit: cover;
          border-radius: 5px;
        }
        &__topic {
          font-size: 1.5rem;
          font-weight: 700;
          color: $mainColor;
          margin-bottom: 10px;
          line-height: 1.5;
        }
        &__text {
          margin-bottom: 20px;
          color: $subColor;
          &__subtitle {
            margin-bottom: 10px;
            font-weight: 700;
            font-size: 1.1rem;
            color: $mainColor;
          }
          li {
            margin-bottom: 10px;
            line-height: 1.5;
          }
        }
        &__button {
          @include customButton;
        }
        .case-card-content {
          padding: 0 40px;
        }
      }
      .left-card {
        .case-card-content {
          padding-right: 160px;
        }
        .case-card__button {
          margin: 0;
        }
      }
      .right-card {
        text-align: right;
        .case-card-content {
          padding-left: 160px;
        }
        .case-card__button {
          margin: 0;
          margin-left: auto;
        }
      }
    }
  }
  .range-card-container {
    .range-card {
      width: 350px;
      height: 250px;
      border-radius: 5px;
      margin-bottom: 30px;
      img {
        width: 100%;
        height: calc(100% - 36px);
        object-fit: cover;
        position: relative;
        border-radius: 0 0 5px 5px;
        object-position: initial;
      }
      &__title {
        width: 100%;
        text-align: center;
        color: #fff;
        background-color: $mainColor;
        padding: 10px;
        border-radius: 5px 5px 0 0;
      }
      &__button {
        @include customButton;
        position: absolute;
        bottom: 10%;
        left: calc(50% - 75px);
        z-index: 99;
        a {
          display: block;
          text-decoration: none;
          color: #fff;
        }
      }
      .disabled-button {
        background-color: #666666;
        box-shadow: 3px 3px 0 #666666;
        border-color: #ccc;
      }
      // .coming-soon-text {
      //   width: calc(100% - 30px);
      //   color: #fff;
      //   font-weight: 700;
      //   text-align: center;
      //   position: absolute;
      //   font-size: 2rem;
      //   top: 45%;
      //   z-index: 100;
      //   opacity: 0;
      //   transition: 0.3s;
      //   user-select: none;
      // }
    }
    // .link-not-done {
    //   transition: 0.4s all;
    //   &:hover {
    //     .coming-soon-text {
    //       opacity: 1;
    //     }
    //     img {
    //       filter: brightness(0.3);
    //     }
    //   }
    // }
  }
  &__publicity {
    margin: 30px 0;
    padding: 20px;
    background-color: $mainColor;
    color: #fff;
    &__title {
      font-size: 1.5rem;
      text-align: center;
      font-weight: 700;
      padding-bottom: 20px;
    }
    .content-block {
      text-align: center;
      .number-text {
        font-size: 2rem;
        margin: 0 5px;
      }
      &__top {
        background-color: darken($mainColor, 20%);
        padding: 20px 0;
        margin-bottom: 10px;
        &__topic {
          font-size: 1.2rem;
          font-weight: 700;
        }
        p {
          margin-top: 10px;
        }
      }
      &__bottom {
        font-size: 1.5rem;
        font-weight: 700;
        padding: 10px;
      }
    }
  }
  @include pad {
    &__analyse,
    &__use-range {
      .case-card-container {
        .left-card,
        .right-card {
          .case-card-content {
            padding: 0 40px;
          }
          .case-card__button {
            margin: 0 auto;
          }
        }
      }
    }
    .range-card-container {
      .range-card {
        // .coming-soon-text {
        //   top: 35%;
        // }
      }
    }
  }
  @include mobile {
    &__banner {
      padding: 60px 0;
      &__title {
        font-size: 1.3rem;
      }
    }
    &__analyse,
    &__use-range {
      &__title {
        font-size: 1.3rem;
        line-height: 1.5;
      }
      .case-card-container {
        .case-card {
          text-align: center;
          &__topic {
            margin-top: 20px;
            font-size: 1.3rem;
          }
        }
        .left-card {
          .case-card-content {
            padding: 0 15px;
          }
        }
        .right-card {
          flex-direction: column-reverse;
          .case-card-content {
            padding: 0 15px;
          }
          &::after {
            width: 0;
            height: 0;
            margin: 0;
          }
          &::before {
            content: '';
            width: 100%;
            height: 3px;
            margin: 40px 0;
            background-image: linear-gradient(
              to right,
              #ccc 0%,
              #ccc 50%,
              transparent 50%
            );
            background-size: 20px 3px;
            background-repeat: repeat-x;
          }
        }
      }
    }
    .range-card-container {
      .range-card {
        width: 100%;
        // .coming-soon-text {
        //   top: 40%;
        // }
      }
    }
    &__publicity {
      padding: 15px;
      &__title {
        font-size: 1.2rem;
        line-height: 1.5;
        padding: 0 50px 10px 50px;
      }
      .content-block {
        padding: 0;
        padding-top: 10px;
        .number-text {
          font-size: 2rem;
        }
        &__top {
          margin-bottom: 0;
          &__topic {
            font-size: 1rem;
          }
          p {
            font-size: 0.8rem;
          }
        }
        &__bottom {
          font-size: 1.2rem;
        }
      }
    }
  }
  @include mobileXS {
    &__banner {
      &__title {
        white-space: pre;
        font-size: 1.3rem;
      }
    }
    &__analyse,
    &__use-range {
      &__title {
        font-size: 1.3rem;
        padding: 0 40px;
        margin: 20px 0;
      }
      .case-card-container {
        .case-card {
          &__topic {
            font-size: 1.1rem;
          }
        }
      }
    }
    &__publicity {
      &__title {
        padding: 0 0 10px 0;
      }
    }
  }
}
