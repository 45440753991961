.buy-point-terms {
  width: 90%;
  height: calc(#{$minMainContentHeight} - 20px);
  background-color: #fff;
  padding: 10px;
  margin: 10px auto;
  border-radius: 5px;
  box-shadow: 3px 3px 5px #3333;
  letter-spacing: 0.5px;
  line-height: 1.5;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  h2 {
    font-weight: bold;
    font-size: 1.6rem;
    text-align: center;
  }
  h3 {
    font-weight: bold;
    margin: 1rem 0;
    font-size: 1.2rem;
  }
  h4 {
    margin-bottom: 0.5rem;
    padding: 5px;
    border: 1px solid #000;
  }
  p {
    margin-bottom: 0.4rem;
  }
  .terms-container {
    max-height: calc(100vh - 243px);
    overflow-y: scroll;
    width: 100%;
    background-color: lighten($subLightColor, 2%);
    border: 1px solid #3333;
    .link {
      padding: 0 5px;
      cursor: pointer;
      color: #4040ff;
      &:hover {
        color: #0303be;
      }
    }
    .terms-content {
      padding: 20px;
    }
    .checked-container {
      border: 1px solid #000;
      padding: 0 20px;
      margin: 20px 0;
      span {
        font-weight: bolder;
        cursor: pointer;
      }
      .checkbox-container {
        display: flex;
        align-items: center;
        transform: translateX(-10px);
        .MuiButtonBase-root {
          &:hover {
            background-color: lighten($mainColor, 50%);
          }
        }
        span {
          font-size: 1.2rem;
          @include mobile {
            font-size: 0.85rem;
          }
        }
      }
    }
  }
  .btn-container {
    .btn {
      @include customButton;
      display: block;
      width: 25%;
      padding: 5px;
      margin: 20px auto;
      display: flex;
      justify-content: center;
      &--disabled {
        background-color: gray;
        box-shadow: 3px 3px 0 gray;
        border-color: #f1f1f1;
        font-weight: normal;
        &:hover {
          background-color: gray;
        }
      }
      &--return {
        background-color: #fff;
        color: #000;
        box-shadow: 3px 3px 0 $mainColor;
        &:hover {
          background-color: lighten($mainColor, 50%);
          color: $mainColor;
        }
      }
    }
  }
  @include mobile {
    .btn-container {
      margin-top: 30px;
      .btn {
        width: 100%;
      }
    }
    .checked-container {
      span {
        display: block;
      }
    }
  }
}
