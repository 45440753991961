.side-menu {
  .space-toggle-btn {
    width: 100%;
    border-radius: 0;
    background-color: #37244d !important;
    border: none;
    padding: 16px;
    padding-left: 30px;
    box-shadow: none;
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
    text-overflow: ellipsis;
    svg {
      font-size: 24px;
    }
    &:focus {
      box-shadow: none !important;
    }
    &:hover {
      background-color: lighten(#37244d, 10%) !important;
    }
    &:active {
      box-shadow: none;
      border: none;
      background-color: lighten(#37244d, 10%) !important;
    }
    span {
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .dropdown {
    .teams-container {
      max-height: calc(32px * 5);
      overflow-y: auto;
      a {
        margin-right: -6px;
      }
      // transform: scaleX(0.95);
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(174, 117, 228, 0.664);
        background-color: transparent;
      }

      &::-webkit-scrollbar {
        width: 6px;
        background-color: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $subPurple;
      }
    }
    .dropdown-menu {
      border: unset;
      box-shadow: 3px 3px 10px rgba(3, 3, 3, 0.384);
    }
    .dropdown-menu.show {
      margin-left: 30px !important;
      padding: 0;
      border-radius: 5px;
    }
    .dropdown-item {
      max-width: 200px;
      height: 32px;
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-align: center;
      line-height: 1.5;
      svg {
        font-size: 20px;
      }
    }
    .my-space-tab {
      min-height: 35px;
      line-height: 1.75;
      background-color: $subPurple;
      margin-bottom: 0;
      color: #fff;
      border-radius: 5px 5px 0 0;
      &:hover {
        background-color: lighten($subPurple, 5%);
      }
    }
    .add-new-group-btn {
      background-color: $subColor;
      color: #fff;
      border-radius: 0 0 5px 5px;
      transform: translateY(2px);
      &:hover {
        background-color: darken($subColor, 10%);
      }
    }
  }
  .space-pages {
    width: 100%;
    .use-platform-btn {
      width: calc(100% - 60px);
      margin: 10px auto;
      color: #fff;
      border: 1px solid #fff;
      border-radius: 5px;
      text-align: center;
      padding: 10px;
      user-select: none;
      cursor: pointer;
      transition: 0.3s;
      &:hover {
        background-color: rgba(255, 255, 255, 0.3);
      }
      &:active {
        background-color: $subPurple;
        border-color: $subPurple;
      }
    }
    .page-label {
      display: flex;
      align-items: center;
      padding: 10px 10px 10px 30px;
      color: #fff;
      user-select: none;
      cursor: pointer;
      &__icon {
        margin-right: 10px;
        svg {
          width: 20px;
          height: 20px;
          fill: #fff;
        }
      }
      &:hover {
        background-color: lighten($mainColor, 10%);
      }
      &--active {
        font-weight: bold;
        color: $mainColor;
        background-color: #fff;
        svg {
          fill: $mainColor;
        }
        &:hover {
          background-color: #fff;
        }
      }
    }
    .platform-btn {
      margin: 10px 30px;
      border: 1px solid #fff;
      color: #fff;
      padding: 10px;
      border-radius: 5px;
      text-align: center;
      user-select: none;
      cursor: pointer;
      &:hover {
        background-color: #905aef;
      }
    }
  }
  @include mobile {
    width: 100%;
    .space-toggle-btn {
      width: 100%;
      border-radius: 0;
      background-color: #37244d !important;
      border-color: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px 12px;
      padding-left: 50px;
      &__title {
        max-width: 100px;
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .dropdown {
      .teams-container {
        max-height: calc(32px * 5);
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        a {
          margin-right: -6px;
        }
        .dropdown-item {
          line-height: 1.5;
          min-height: 32px;
        }
      }
      .dropdown-menu.show {
        transform: unset !important;
        transform: translate(40%, 44px) !important;
      }
      .add-new-group-btn {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .menu-drawer {
      width: 50px;
      min-height: calc(100vh - #{$navHeight} - #{$footerHeight} + 38.65px);
      background-color: $mainColor;
      position: absolute;
      z-index: 100;
      top: 0;
      transition: 0.5s all;
      .drawer-toggle-btn {
        transform: translateX(0);
        transition: 0.5s all;
        padding: 9.5px;
        user-select: none;
        cursor: pointer;
        svg {
          width: 30px;
          fill: #fff;
        }
      }
      .space-pages {
        overflow: hidden;
        .page-label {
          height: 40px;
          padding: 0 0 0 15px;
          span {
            white-space: pre;
            opacity: 0;
            transition: 0.2s opacity ease-out;
          }
        }
        .use-platform-btn {
          width: 100%;
          border: none;
          border-radius: unset;
          // background-color: lighten($mainColor, 30%);
          background-color: #905aef;
          &:hover {
            background-color: darken(#905aef, 10%);
          }
          &__icon {
            margin-right: 10px;
            svg {
              width: 20px;
              height: 20px;
              fill: #fff;
            }
          }
        }
      }
      &--active {
        width: 200px;
        box-shadow: 3px 3px 10px rgba(26, 5, 39, 0.384);
        .drawer-toggle-btn {
          transform: translateX(150px);
        }
        .space-pages {
          .page-label {
            padding: 10px 15px;
            span {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}
