.cardMiddle {
  position: relative;
  @include flex_func(space-evenly, center);
  flex-wrap: wrap;
  top: -100px;
  .card {
    flex-basis: 320px;
    width: 320px;
    height: 350px;
    margin: 2%;
    box-shadow: 0 5px 10px 0 #ccc;
    background-color: transparent;
    .cardImage {
      width: 100%;
      height: 50%;
      //   background-image: url(https://picsum.photos/seed/picsum/200/300);
      border-radius: 3%;
    }
    .cardText {
      padding: 10px 20px;
      @include flex_func(flex-start, flex-start, column);

      span {
        color: rgb(122, 122, 122);
      }
    }
    .cardLink {
      width: 100%;
      padding: 10px 20px;
      a {
        text-decoration: none;
      }
    }
  }
}

