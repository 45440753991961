.error-page {
  width: 100%;
  height: 100%;
  text-align: center;
  background: linear-gradient(45deg, #545ba8 40%, $mainColor 60%);
  padding: 30px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .content {
    width: 50%;
    min-height: 60vh;
    padding: 30px 0;
    background-color: #ffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h2 {
      font-size: 8rem;
      font-weight: bold;
    }
    p {
      margin: 1rem 0;
      font-size: 1.5rem;
    }
    svg {
      width: 6rem;
      height: 6rem;
    }
    button {
      @include customButton;
    }
  }
  @include UltraHD {
    .content {
      height: 40vh;
    }
  }
  @include pad {
    .content {
      width: 80%;
    }
  }
}
