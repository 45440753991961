.modal-form-container {
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(2, 1fr);
  fieldset {
    position: relative;
    grid-column: 1/3;
    border: 1px solid lightgray;
    padding: 12px;
    display: grid;
    gap: 12px;
    grid-template-columns: repeat(2, 1fr);
    legend {
      width: fit-content;
      padding: 0 8px;
    }
    & fieldset {
      grid-template-columns: 1fr 3fr;
      border: 1px dotted lightgray;
    }
  }
  .bind-accounts {
    grid-column: 1/3;
    display: flex;
    gap: 8px;
    flex-direction: column;
  }
  .note-textarea {
    grid-column: 1/3;
    & label {
      display: flex;
      flex-direction: column;
      gap: 8px;
      color: rgba(0, 0, 0, 0.54);
      padding: 0;
      font-size: 1rem;
      font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
      font-weight: 400;
      line-height: 1;
      letter-spacing: 0.00938em;
      & textarea {
        border: 1px solid rgba(0, 0, 0, 0.54);
        resize: none;
        padding: 8px;
        &:focus {
          border: 1px solid #5a36a1;
          outline-color: #5a36a1;
        }
      }
      &:focus-within {
        color: #5a36a1;
      }
    }
  }
  button[title='刪除攝影鏡頭'] {
    position: absolute;
    right: 0;
    top: -12px;
    color: var(--danger);
  }
}
.backdrop {
  z-index: 1050;
}

.host-info {
  .tab-content {
    background-color: var(--light);
    border-radius: 0 0 5px 5px;
    padding: 24px;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
      0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    &.empty {
      text-align: center;
      font-size: 24px;
      padding: 36px 0;
    }
  }
  .nav-tabs {
    background-color: #5a36a1;
    padding: 8px;
    padding-bottom: 0px;
    border-radius: 5px 5px 0 0;
    &.empty {
      padding: 20px;
    }
  }
  .tab-container {
    display: flex;
    flex-direction: column;
    gap: 12px;
    small {
      display: inline-block;
      margin-left: auto;
      color: gray;
    }
  }
  .fieldset-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: flex-start;
    row-gap: 12px;
    @media (max-width: 1023px) {
      display: flex;
      flex-direction: column;
    }
  }
  .fieldset {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 10px 0;
    .form-control {
      width: unset;
    }
    .form-group {
      margin-bottom: 0;
    }
    .text-field-hint {
      color: gray;
      margin-bottom: 16px;
      display: flex;
      align-items: center;
    }
    &.cams {
      grid-column: 1/3;
    }
  }
  .title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 20px;
    &::before {
      width: 10px;
      height: 20px;
      background-color: $mainColor;
      margin-right: 5px;
      content: '';
    }
  }
  .subtitle {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 12px;
    &::before {
      width: 5px;
      height: 20px;
      background-color: $mainColor;
      margin-right: 5px;
      content: '';
    }
  }
  .properties {
    font-size: 16px;
    display: flex;
    flex-direction: column;
    row-gap: 12px;
  }
  .property {
    padding-left: 15px;
    display: flex;
    column-gap: 12px;
    min-height: 24px;
    @media (max-width: 415px) {
      column-gap: 0px;
    }
  }
  .property-name {
    width: 140px;
    display: flex;
    align-items: center;
    @media (max-width: 415px) {
      width: 138px;
    }
  }
  .property-value {
    flex: 1;
    display: flex;
    align-items: center;
    &.center {
      justify-content: center;
    }
  }
  .subproperty-container {
    padding-left: 15px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    & .property-name {
      width: 80px;
    }
    & .property-value {
      overflow-wrap: anywhere;
      width: calc(100% - 80px);
      flex: none;
      line-height: 1.5;
    }
    row-gap: 16px;
    column-gap: 12px;
    @media (max-width: 1023px) {
      display: flex;
      flex-direction: column;
    }
  }
  .nav-link {
    color: white;
  }
  .cams > .properties > .property {
    @media (max-width: 415px) {
      flex-direction: column;
      row-gap: 12px;
    }
  }
}
