.support-page {
  .control-tab {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 30px 0;
    border-bottom: 1px solid rgba(51, 51, 51, 0.4);
    .nav-link {
      width: 20%;
      text-align: center;
      padding-bottom: 10px;
      cursor: pointer;
      position: relative;
      &::after {
        content: '';
        width: 0;
        height: 5px;
        background-color: lighten($mainColor, 20%);
        position: absolute;
        bottom: -2.5px;
        left: 0;
        transition: all 0.2s ease-in-out;
      }
      &:hover {
        color: $mainColor;
        &::after {
          content: '';
          width: 100%;
          color: lighten($mainColor, 20%);
        }
      }
      &--active {
        color: $mainColor;
        font-weight: bold;
        &::after {
          content: '';
          width: 100%;
          background-color: $mainColor;
        }
      }
    }
  }
  .login-hint {
    text-align: center;
    margin: 16px 0;
    letter-spacing: 1px;
    line-height: 1.5;
  }
  &__title {
    width: 100%;
    display: flex;
    align-items: center;
    padding-top: 60px;
    .title-line {
      width: calc((100% - 300px) / 2);
      height: 3px;
      background-color: $mainColor;
    }
    .topic {
      width: 300px;
      background-color: $mainColor;
      font-size: 1.5rem;
      color: #f7f7fb;
      text-align: center;
      padding: 10px;
      font-weight: bold;
      border-radius: 5px;
    }
  }
  .video-container {
    padding-bottom: 60px;
    h3 {
      font-size: 1.2rem;
      margin-bottom: 10px;
      color: $mainColor;
      font-weight: 600;
    }
    .video-player {
      width: 100% !important;
      border: 1px solid $mainColor;
      border-radius: 5px;
    }
  }
}
