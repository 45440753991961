.buy-point-page {
  padding: 60px 0;
  position: relative;
  .MuiButtonBase-root {
    @include customButton;
    width: 50%;
    max-width: 250px;
    font-size: 1rem;
    padding: 5px;
    &:hover {
      box-shadow: 3px 3px 0 $mainColor;
    }
  }
  .btn-container {
    .Mui-disabled {
      border-color: #bbb;
      box-shadow: 3px 3px 0 #ccc;
    }
    .step-back-btn {
      background-color: #fff;
      color: #000;
      box-shadow: 3px 3px 0 lighten($mainColor, 20%);
      &:hover {
        background-color: lighten($mainColor, 50%);
        color: $mainColor;
      }
    }
  }
  .MuiStepIcon-root.MuiStepIcon-completed {
    color: $mainColor;
  }
  .MuiStepIcon-root.MuiStepIcon-active {
    color: $mainColor;
  }

  .buyPointsBody {
    @extend .main-content;
    width: 80%;
    margin: 0 auto;
    .buyPointsSection {
      width: 100%;
      background-color: white;
      border-radius: 10px;
      table#package-list-table {
        tbody {
          tr {
            cursor: pointer;
            &.highlight {
              background-color: #a19fff;
              color: white;
              font-weight: 500;
            }
          }
        }
      }
      .points_row {
        @include flex_func(center, center, column);
      }
      .points_column {
        padding: 0px;
        width: 100%;
        margin: 20px 0;
        @include flex_func(space-around, flex-end);
        flex-direction: row-reverse;
        input {
          margin: 0;
          position: initial;
          width: 100%;
          height: 24px;
        }
        label {
          color: black;
          width: 100%;
        }
      }
      .buyPointsButton {
        @include flex_func(center);
      }
      .step-block-container {
        overflow: auto;
        padding: 0 50px;
        .step-block {
          width: 300px;
          background-color: unset;
          box-shadow: unset;
          color: $subColor;
        }
        @include mobile {
          padding: 0 10px;
        }
      }
      .scroll-hint {
        width: 100%;
        margin: 0 auto;
        text-align: center;
        padding: 10px;
        color: $subColor;
        letter-spacing: 3px;
        font-size: 12px;
      }
      .scrollbar-wrapper,
      .quotation-wrapper {
        width: 100%;
        border: none 0px red;
        overflow-x: scroll;
        overflow-y: hidden;
      }
      .scrollbar-wrapper {
        height: 20px;
      }
      .quotation-wrapper {
        height: $quotationHeight;
      }
      .top-scrollbar {
        width: $quotationWidth;
        height: 20px;
      }
    }
    @media (max-width: 900px) {
      width: 100%;
    }
    @include pad {
      .buyPointsSection {
        margin: 0 auto;
        width: 95%;
        .step-block-container {
          padding: 0 20px;
          .step-block {
            width: 80%;
            line-height: 1.5;
          }
        }
      }
    }
    @include mobile {
      margin: 0 auto;
      .btn-container {
        flex-direction: column-reverse;
      }
      .MuiButtonBase-root :not(.checkbox) {
        width: 100%;
      }
      .MuiStepper-root {
        padding: 10px;
        padding-bottom: 20px;
      }
      .buyPointsSection {
        width: 100%;
        .step-block-container {
          padding: 0 10px;
          .step-block {
            width: 80%;
            line-height: 1.5;
          }
        }
      }
    }
  }
  .choose-payment {
    width: 100%;
    h3 {
      text-align: center;
      font-weight: bold;
      font-size: 1.8rem;
      color: $mainColor;
      margin: 20px 0;
      @include mobile {
        font-size: 1.3rem;
      }
    }
    .warning-text {
      text-align: center;
      color: red;
      line-height: 1.5;
    }
    &.invoice {
      width: 60%;
      margin: 0 auto;
      .properties {
        margin: 0;
      }
      @include mobile {
        width: 100%;
        .properties {
          margin: 0 10px;
        }
      }
    }
    .MuiFormGroup-root {
      justify-content: space-around;
      margin-bottom: 10px;
      .MuiFormControlLabel-root {
        .MuiButtonBase-root {
          background-color: unset;
          border: none;
          box-shadow: none;
          width: 35px;
          margin: unset;
          border-radius: 50%;
          &:hover {
            background-color: lighten($mainColor, 50%);
          }
        }
      }
    }
    .properties {
      font-size: 16px;
      display: flex;
      row-gap: 12px;
      transition: all 0.5s ease;
      overflow: hidden;
      margin: 0 20px;
      padding-top: 15px;
      justify-content: space-between;
      position: relative;
      max-height: 1000px;
      border-top: 3px solid transparent;
      flex-wrap: wrap;
      .property {
        column-gap: 12px;
        min-height: 24px;
        width: 49%;
        &.checkbox {
          .MuiButtonBase-root {
            background-color: unset;
            border: none;
            box-shadow: none;
            width: 35px;
            margin: unset;
            border-radius: 50%;
            &:hover {
              background-color: lighten($mainColor, 50%);
            }
          }
          .property-name {
            cursor: pointer;
          }
        }
        .property-name {
          width: 125px;
          display: flex;
          align-items: center;
        }
      }
    }
    .payment-card-container {
      width: 100%;
      padding: 20px;
      ul {
        padding-left: 10px;
        h5 {
          font-weight: bold;
          font-size: 1.1rem;
          text-align: center;
          padding: 5px 0;
          margin: 0 auto;
          margin-bottom: 15px;
          border-bottom: 3px solid $mainColor;
          @include pad {
            font-size: 0.9rem;
          }
        }
        li {
          list-style-type: square;
          margin-bottom: 10px;
          list-style-position: inside;
          @include pad {
            font-size: 14px;
          }
        }
      }
      .payment-card {
        width: 100%;
        border: 1px solid $subColor;
        border-radius: 5px;
        text-align: center;
        padding: 20px;
        margin-bottom: 10px;
        user-select: none;
        cursor: pointer;
        &--active {
          background-color: lighten($mainColor, 40%);
        }
        &--disabled {
          filter: grayscale(1);
          background-color: #eee;
          cursor: not-allowed;
        }
        img {
          width: 100%;
        }
        h4 {
          font-weight: bold;
        }
      }
      @include mobile {
        padding: 5px;
        .payment-card {
          padding: 10px;
          h4 {
            font-size: 12px;
          }
        }
        ul {
          padding-left: 0;
          h5 {
            font-size: 12px;
          }
          li {
            font-size: 12px;
            list-style: none;
          }
        }
      }
    }
    @include mobile {
      .properties {
        font-size: 14px;
        margin: 0 10px;
        flex-direction: column;
        .property {
          width: 100%;
          &.checkbox {
            flex-direction: row;
          }
          .property-name {
            margin: 5px 0;
            font-weight: bold;
          }
        }
      }
    }
  }
  .order-wrap {
    width: 100%;
    border: 3px solid $mainColor;
    border-radius: 5px;
    padding: 10px;
    .order-container {
      width: 100%;
      padding: 20px;
      border: 3px solid $mainColor;
      border-radius: 5px;
      position: relative;
      &::before {
        content: '';
        width: 80%;
        height: calc(100% + 10px);
        position: absolute;
        border-top: 10px solid #fff;
        border-bottom: 10px solid #fff;
        top: -5px;
        left: 10%;
      }
      &::after {
        content: '';
        width: calc(100% + 10px);
        height: 68%;
        position: absolute;
        border-left: 10px solid #fff;
        border-right: 10px solid #fff;
        left: -5px;
        top: 17%;
      }
      .order-title {
        text-align: center;
        font-weight: bold;
        font-size: 1.8rem;
        color: $mainColor;
        margin-bottom: 30px;
        @include mobile {
          font-size: 1.3rem;
        }
      }
      .order-detail {
        display: flex;
        margin-bottom: 20px;
        padding-left: 10px;
        &__topic {
          width: 120px;
          font-weight: 700;
          color: $mainColor;
          &::before {
            content: '|';
            background-color: $mainColor;
            margin-right: 5px;
          }
        }
      }
      .pay-btn {
        display: block;
        margin-top: 30px;
      }
    }
    @include mobile {
      .order-container {
        padding: 10px;
        padding-bottom: 20px;
        &::before {
          width: 68%;
          left: 16%;
        }
        &::after {
          height: 80%;
          top: 10%;
        }
        .order-title {
          margin-bottom: 20px;
        }
        .order-detail {
          display: block;
          &__topic {
            margin-bottom: 10px;
          }
        }
      }
    }
  }
  .buy-step-intro {
    .buy-step-card-main {
      width: 80%;
      height: 50px;
      border: 1px solid $subColor;
      border-radius: 5px;
      margin: 0 auto;
      margin-bottom: 15px;
      font-weight: 700;
      position: relative;
      font-size: 1.2rem;
      background-color: lavender;
      .buy-step-card {
        text-align: center;
        padding: 14.5px 0;
        border-radius: 5px;
      }
      .buy-step-number {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        border: 1px solid $subColor;
        position: absolute;
        left: -15px;
        top: calc(25px - 15px);
        background-color: $mainColor;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      @include pad {
        width: 90%;
      }
      @include mobile {
        font-size: 1rem;
        .buy-step-card {
          padding: 16px 0;
        }
      }
    }
    .buy-step-card-main.choose-payment-step {
      height: unset;
      .buy-step-number {
        top: calc(50% - 15px);
      }
      .buy-step-card {
        padding-bottom: 5px;
        .sub-step-container {
          display: flex;
          margin-top: 10px;
          justify-content: space-between;
          .buy-step-card-sub {
            border-top: 0;
            width: 45%;
            .buy-step-card {
              border: 1px solid #000;
              padding: 14.5px 0;
            }
          }
        }
      }
    }
    .buy-step-card-sub {
      width: 50%;
      text-align: center;
      padding: 0 10px;
      font-weight: normal;
      .buy-step-card {
        background-color: #fff;
        font-size: 1rem;
        height: 100%;
      }
      &__subtopic {
        font-size: 1.1rem;
        font-weight: 700;
      }
      &__step-container {
        margin-top: 20px;
        &__step {
          margin: 10px auto;
        }
      }
      @include mobile {
        padding: 0 5px;
        .buy-step-card {
          padding: 10px 0;
        }
        &__subtopic {
          font-size: 14px;
        }
      }
    }
  }
  .choose-plan-list {
    .title {
      text-align: center;
      font-size: 1.8rem;
      font-weight: 700;
      color: $mainColor;
      margin-bottom: 20px;
    }
    .subtitle {
      text-align: center;
      margin-bottom: 15px;
    }
    .plan-container {
      margin-bottom: 30px;
      .plan-card-head {
        display: flex;
        border-bottom: 2px solid #000;
        .head-title {
          padding: 10px;
          display: grid;
          place-content: center;
        }
      }
      .plan-card {
        display: flex;
        align-items: center;
        text-align: center;
        border-bottom: 1px solid #ccc;
        &__price,
        &__point {
          text-align: right;
        }
        &__name,
        &__point,
        &__price,
        &__amount,
        &__add {
          padding: 10px;
          .add-btn,
          .delete-btn {
            width: 100%;
            user-select: none;
            cursor: pointer;
            &:hover {
              .MuiSvgIcon-root {
                color: #888;
              }
            }
            &:active {
              .MuiSvgIcon-root {
                transform: scale(0.8);
              }
            }
          }
          .add-btn.disabled,
          .delete-btn.disabled {
            color: #bbb;
          }
        }
        &__amount {
          .counter-container {
            display: flex;
            justify-content: center;
          }
          input {
            width: 100%;
          }
        }
        &__point {
          display: flex;
          justify-content: center;
          align-items: center;
          .bonus {
            color: $mainColor;
            font-weight: 700;
          }
          .img-container {
            height: 32px;
            display: flex;
            justify-content: flex-end;
            padding-right: 5px;
            img {
              height: 100%;
            }
            @media screen and (max-width: 768px) {
              display: none;
            }
          }
          .text {
            display: flex;
            justify-content: flex-start;
            padding-left: 0;
            align-items: center;
            height: 32px;
            @media (max-width: 450px) {
              padding-right: 0;
            }
          }
          .unBonus-point {
            margin-right: 5px;
            @include mobile {
              margin-right: 2px;
            }
          }
        }
        .flex-end {
          justify-content: flex-end;
        }
      }
    }
    .order-total-amount {
      width: 100%;
      text-align: right;
      .price,
      .point {
        padding: 10px;
        display: flex;
        .text {
          text-align: start;
        }
        .total {
          font-weight: bolder;
        }
        .text,
        .total {
          width: 50%;
        }
      }
    }
    @include mobile {
      .title {
        font-size: 1.3rem;
        margin-bottom: 10px;
      }
      font-size: 12px;
      .plan-container {
        .plan-card-head {
          .head-title {
            padding: 5px;
          }
        }
        .plan-card {
          &__name,
          &__price,
          &__amount {
            padding: 5px;
          }
          &__point,
          &__add {
            padding: 5px 0;
          }
          &__price {
            min-width: 77px;
          }
        }
      }
    }
  }
  .p-4 {
    background: lighten($mainColor, 50%);
    border-radius: 10px;
    h5,
    h6 {
      font-weight: bolder;
    }
    h5 {
      font-size: 2rem;
    }
    h6 {
      font-size: 1.5rem;
    }
  }
}
.swal-modal .swal-text,
.swal-footer {
  text-align: center;
}
