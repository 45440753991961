.scroll-hint-mask {
  width: 100%;
  height: 100%;
  // height: 100vh;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  z-index: 99;
  text-align: center;
  padding: 140px 0;
  .hint-text {
    width: 80%;
    margin: 0 auto;
    .swipe-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 50px 0;
      img {
        margin: 0 30px;
        width: 40px;
      }
    }
    h2 {
      width: 65%;
      margin: 0 auto;
      font-size: 20px;
      letter-spacing: 3px;
      font-weight: bold;
      padding-bottom: 8px;
      border-bottom: 1px solid #fff;
    }
  }
  .close-btn {
    min-width: 100px;
  }
  @media screen and (min-width: 768px) {
    display: none;
  }
}
