.custom-news-carousel-card {
  width: 100%;
  height: 300px;
  border: 1px solid #333333;
  padding: 10px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 5px 5px 0 darken($subLightColor, 10%);
  .swiper-container {
    width: 100%;
    height: 100%;
  }
  .swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: 0;
  }
  .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
  }
  .swiper-button-prev.swiper-button-disabled,
  .swiper-button-next.swiper-button-disabled {
    text-shadow: unset;
    color: transparent;
  }
  .swiper-button-prev,
  .swiper-button-next {
    text-shadow: 2px 2px 5px rgba(51, 51, 51, 0.527);
    &:hover {
      color: #00ffc8;
    }
  }
  .swiper-slide {
    height: 280px;
  }
  .swiper-pagination-bullet-active {
    background: #00ffc8;
  }
  &__content {
    display: flex;
    width: 100%;
    height: 280px;
    background-color: $subLightColor;
    border-radius: 0 5px 5px 0;
    &__img {
      width: 60%;
      height: 100%;
      border-radius: 5px 0 0 5px;
      border-right: 3px solid #fff;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 5px 0 0 5px;
      }
    }
    &__textarea {
      width: 40%;
      padding: 20px;
      padding-right: 30px;
      line-height: 1.2;
      position: relative;
      .news-type {
        padding: 5px 10px;
        color: #fff;
        margin-right: 10px;
        border-radius: 5px;
        &--red {
          background-color: #c11a1a;
        }
        &--orange {
          background-color: #da6506;
        }
        &--blue {
          background-color: #006eff;
        }
      }
      .time {
        font-weight: bold;
        padding-bottom: 10px;
        border-bottom: 1px solid #333333;
      }
      .topic {
        font-size: 1.4rem;
        font-weight: bold;
        margin: 1rem 0;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
        box-sizing: border-box;
      }
      .paragraph {
        width: 100%;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
        text-overflow: ellipsis;
        box-sizing: border-box;
        color: #333333;
      }
      .btn {
        @include customButton;
        width: calc(100% - 40px);
        padding: 5px;
        background-color: $mainColor;
        color: #fff;
        margin-top: auto;
        transition: 0.3s all ease-in-out;
        position: absolute;
        bottom: 20px;
      }
    }
  }
  @include pad {
    &__content {
      &__img {
        width: 55%;
      }
      &__textarea {
        width: 45%;
        font-size: 14px;
      }
    }
  }
  @include mobile {
    height: 350px;
    .swiper-slide {
      height: 300px;
    }
    &__content {
      height: 300px;
      flex-direction: column;
      background-color: #fff;
      position: relative;
      &__img {
        width: 100%;
        height: 140px;
        border-radius: 5px 5px 0 0;
        border-right: unset;
        img {
          object-fit: cover;
          border-radius: 5px 5px 0 0;
        }
      }
      &__textarea {
        width: 100%;
        padding: 15px 30px;
        position: unset;
        .time {
          font-size: 12px;
        }
        .topic {
          font-size: 16px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          text-overflow: ellipsis;
          box-sizing: border-box;
          margin: 5px 0;
        }
        .paragraph {
          font-size: 14px;
          -webkit-line-clamp: 1;
        }
        .btn {
          width: calc(100% - 20px);
          left: 10px;
          bottom: 0;
        }
      }
    }
  }
}
