.news-article {
  padding: 30px 20px;
  background-color: #fff;
  margin-bottom: 30px;
  &__title {
    text-align: center;
    font-weight: bold;
    font-size: 1.5rem;
    line-height: 1.2;
  }
  &__type {
    font-size: 14px;
    margin-right: 0.5rem;
  }
  &__time {
    font-size: 14px;
    color: #333333;
    padding-left: 5px;
    border-left: 5px solid lighten($mainColor, 20%);
  }
  &__btn {
    @include customButton;
    margin: 0 auto;
    margin-top: 30px;
    padding: 10px;
    text-align: center;
    cursor: pointer;
    transition: 0.3s all ease-in-out;
    &:hover {
      background-color: darken($mainColor, 10%);
    }
  }
  .news-display-wrapper {
    width: 100%;
    min-height: calc(#{$minMainContentHeight} - 230px);
    line-height: 1.5;
    img {
      max-width: 100%;
    }
  }
  @include pad {
    figure {
      width: 100%;
      img {
        width: 100% !important;
      }
    }
  }
  @include mobile {
    &__title {
      text-align: left;
    }
  }
}
