@mixin UltraHD {
  @media (min-width: 1920px) {
    @content;
  }
}

@mixin laptop {
  @media (max-width: 1200px) {
    @content;
  }
}
@mixin pad {
  @media (max-width: 770px) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: 570px) {
    @content;
  }
}

@mixin mobileXS {
  @media (max-width: 320px) {
    @content;
  }
}
