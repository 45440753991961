.pricing {
  width: 100%;
  background-color: darken($mainColor, 5%);
  padding: 50px 0;
  color: #fff;
  .step-circle {
    min-width: 50px;
    min-height: 50px;
    border-radius: 50%;
    border: 2px solid #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    font-size: 1.3rem;
    font-weight: 700;
  }
  .info-text {
    font-size: 1.2rem;
    font-weight: 700;
    line-height: 1.5;
  }
  .more-btn {
    @include customButton;
    width: unset;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: #fff;
    color: $mainColor;
    box-shadow: 3px 3px 0 #fff;
    border: 1px solid $mainColor;
    line-height: 1.5;
    &:hover {
      background-color: lighten($mainColor, 15%);
      color: #fff;
      border-color: lighten($mainColor, 40%);
      box-shadow: 3px 3px 0 lighten($mainColor, 15%);
    }
  }
  @include mobile {
    .info-container {
      margin-bottom: 20px;
    }
    .more-btn {
      width: 60%;
      margin-top: 10px;
    }
  }
}
