.custom-carousel {
  width: 100%;
  height: 550px;
  position: relative;
  &__top {
    width: 100%;
    .background-img {
      width: 100%;
      height: 380px;
      object-fit: cover;
      object-position: center;
    }
  }
  .carousel {
    width: 800px;
    height: 450px;
    left: calc(50% - 400px);
    position: absolute;
    top: 30px;
    .carousel-indicators {
      bottom: -10%;
      align-items: center;
      li {
        width: 10px;
        height: 10px;
        margin-right: 10px;
        margin-left: 10px;
        border-radius: 50%;
        background-color: $mainColor;
      }
      .active {
        width: 12px;
        height: 12px;
      }
    }
    .carousel-item {
      height: 100%;
      .carousel-btn {
        width: 150px;
        border-radius: 5px;
        margin: 0 auto;
        padding: 10px;
        border: 1px solid #fff;
        user-select: none;
        cursor: pointer;
        background-color: rgba(190, 146, 231, 0.4);
        transition: 0.4s;
        &:hover {
          box-shadow: 3px 3px 5px rgba(32, 11, 53, 0.5);
          border-color: transparent;
          background-color: $subPurple;
        }
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
        cursor: pointer;
        transition: 0.4s ease-in-out;
        &:hover {
          transform: scale(1.025);
        }
      }
      img.hover-effect-off {
        &:hover {
          transform: none;
        }
      }
    }
    .carousel-control-next,
    .carousel-control-prev {
      width: 6%;
      height: 14%;
      margin: auto 0;
      background-color: $mainColor;
    }
    .carousel-control-prev {
      border-radius: 0 5px 5px 0;
    }
    .carousel-control-next {
      border-radius: 5px 0 0 5px;
    }
    .carousel-control-next-icon,
    .carousel-control-prev-icon {
      width: 30px;
      height: 30px;
    }
    .announce-banner {
      text-align: center;
      position: relative;
      &__info {
        width: 100%;
        padding: 20px;
        position: absolute;
        top: 31%;
        h3 {
          font-weight: bold;
          font-size: 1.5rem;
          margin-bottom: 0.5rem;
        }
        p.info-text {
          width: 100%;
          height: calc((1.2rem * 1.2) * 4);
          color: #333333;
          font-size: 1.2rem;
          line-height: 1.2;
          white-space: pre-wrap;
          text-align: left;
          padding: 0 4rem;
          overflow: hidden;
        }
      }
    }
    .carousel-inner {
      height: 100%;
    }
  }
  @include UltraHD {
    height: 1000px;
    &__top {
      .background-img {
        height: 720px;
      }
    }
    .carousel {
      width: 1440px;
      height: 810px;
      left: calc(50% - 720px);
      top: 60px;
      .carousel-indicators {
        li {
          width: 20px;
          height: 20px;
        }
        .active {
          width: 23px;
          height: 23px;
        }
      }
      .carousel-control-next-icon,
      .carousel-control-prev-icon {
        width: 50px;
        height: 50px;
      }
      .announce-banner {
        &__info {
          h3 {
            font-size: 4rem;
          }
          p.info-text {
            font-size: 2.5rem;
            height: calc((2.5rem * 1.2) * 4);
          }
        }
      }
    }
  }
  @include pad {
    height: 515px;
    &__top {
      .background-img {
        height: 320px;
      }
    }
    .carousel {
      width: 90vw;
      height: 51vw;
      left: 5%;
      top: 70px;
      .announce-banner {
        &__info {
          p.info-text {
            font-size: 1rem;
            height: calc((1rem * 1.2) * 4);
          }
        }
      }
    }
  }
  @include mobile {
    height: 300px;
    &__top {
      .background-img {
        height: 155px;
      }
    }
    .carousel {
      top: 20px;
      .carousel-indicators {
        bottom: -20%;
      }
      .carousel-control-next,
      .carousel-control-prev {
        height: 100%;
        background-color: transparent;
      }
      .announce-banner {
        &__info {
          padding: 0 8px;
          h3 {
            font-size: 0.8rem;
            margin-bottom: 5px;
          }
          p.info-text {
            height: calc((12px * 1.2) * 4);
            font-size: 12px;
            padding: 0 5px;
          }
        }
      }
    }
  }
  @include mobileXS {
    height: 220px;
    .carousel {
      .announce-banner {
        &__info {
          height: 65%;
          padding: 0 5px;
          h3 {
            font-size: 12px;
          }
          p.info-text {
            height: calc((8px * 1.9) * 4);
            font-size: 8px;
            line-height: 1;
          }
        }
      }
    }
  }
}
