.home-page {
  background-color: #fff;
  .carousel-display {
    height: 0;
    overflow: hidden;
    transition: 2s all;
    &--on {
      height: 550px;
    }
    @include UltraHD {
      &--on {
        height: 1000px;
      }
    }
    @include pad {
      &--on {
        height: 515px;
      }
    }
    @include mobile {
      &--on {
        height: 300px;
      }
    }
    @include mobileXS {
      &--on {
        height: 220px;
      }
    }
  }
  .title-tab {
    @include titleTab;
  }
  .banner-row {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .banner {
    background: #fff;
    overflow: hidden;
    padding: 0;
    &__logo {
      margin: -120px 0;
      transform: translateX(-120px);
      @media (max-width: 991px) {
        margin: -80px 0;
      }
      @media (max-width: 767px) {
        margin: -160px 0;
      }
      @media (max-width: 599px) {
        margin: -190px 0;
        transform: translateX(0);
      }
      @media (max-width: 570px) {
        margin: -110px 0;
        transform: translateX(0);
      }
      img {
        width: 100%;
      }
    }
    &__title {
      color: #f7f7fd;
      span {
        color: yellow;
        @media (max-width: 1199px) {
          display: block;
          margin-bottom: 1rem;
        }
        @media (max-width: 570px) {
          display: unset;
          margin-bottom: 0;
        }
      }
      h2 {
        font-size: 2.3rem;
        font-weight: bold;
        margin-bottom: 20px;
      }
      p {
        line-height: 1.5;
        font-size: 1.3rem;
        word-break: keep-all;
      }
      &__bottom {
        margin-left: 7.5rem;
        margin-top: 1rem;
        @media (max-width: 1199px) {
          margin-left: 0;
        }
      }
      .try-btn {
        @include customButton;
        width: 100%;
        border-bottom-color: darken($mainColor, 15%);
        border-right-color: darken($mainColor, 15%);
        &:hover {
          border-top-color: lighten($mainColor, 30%);
          border-left-color: lighten($mainColor, 30%);
        }
        a {
          text-decoration: none;
          display: block;
          color: #fff;
        }
      }
    }
    &__img {
      padding-left: 7rem;
      padding-top: 1rem;
      img {
        aspect-ratio: 1;
        height: 120px;
      }
    }
    &__space {
      @media (max-width: 768px) {
        display: none;
      }
    }
    &__video-container {
      position: relative;
      padding-top: 56.25%;
      .video-player {
        width: 100% !important;
        height: 100% !important;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
  .main-content {
    padding: 0;
    min-height: unset;
    .product-feature {
      &__title {
        font-size: 3rem;
        font-weight: 700;
        text-align: center;
        margin: 40px auto;
        word-break: keep-all;
        line-height: 1.2;
        @media (max-width: 515px) {
          font-size: 2.5rem;
        }
      }
      &__sub-title {
        font-size: 1.8rem;
        font-weight: 700;
        text-align: center;
        margin: 24px auto;
        word-break: keep-all;
        line-height: 1.2;
        span:nth-child(2) {
          color: red;
          padding: 0 10px;
        }
        @media (max-width: 450px) {
          font-size: 1.4rem;
        }
      }
      &__feature-container {
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 60px;
        justify-content: space-around;
        &__feature {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 0 20px;
          border-radius: 5px;
          img {
            width: 90%;
            @media (max-width: 767px) {
              width: 60%;
            }
          }
          &__title,
          &__content {
            display: inline-block;
            font-size: 2.5rem;
            font-weight: 700;
            padding: 5px 20px;
            margin: 10px 0;
            word-break: keep-all;
          }
          &__title {
            span:first-child {
              color: red;
            }
          }
          &__content {
            span:nth-child(2) {
              color: red;
            }
          }
          &__content {
            width: 100%;
            text-align: center;
            font-size: 1.8rem;
            word-break: break-word;
            white-space: pre-wrap;
            line-height: 1.5;
            margin-top: 5px;
            @media (max-width: 991px) {
              font-size: 1.3rem;
            }
            @media (max-width: 767px) {
              font-size: 1.8rem;
            }
          }
          @media (max-width: 767px) {
            margin-bottom: 40px;
          }
        }
        @media (max-width: 767px) {
          padding-bottom: 0;
        }
      }
    }
    .product-application {
      &__title {
        font-size: 3rem;
        font-weight: 700;
        text-align: center;
        margin: 40px auto;
        word-break: keep-all;
        line-height: 1.2;
        @media (max-width: 515px) {
          font-size: 2.5rem;
        }
      }
      &__product-container {
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 60px;
        justify-content: space-around;
        &__product {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 0 20px;
          border-radius: 5px;
          img {
            width: 90%;
            @media (max-width: 767px) {
              width: 60%;
            }
          }
          &__name,
          &__title,
          &__sub-title,
          &__content {
            display: inline-block;
            font-size: 2.5rem;
            font-weight: 700;
            padding: 5px 20px;
            margin: 10px 0;
            word-break: keep-all;
          }
          &__name,
          &__content {
            color: #8b8681;
          }
          &__sub-title,
          &__content {
            width: 100%;
            text-align: center;
            font-size: 1.3rem;
            word-break: break-word;
          }
          &__sub-title {
            @media (max-width: 1198px) {
              height: 51px;
            }
            @media (max-width: 767px) {
              height: auto;
            }
          }
          &__content {
            white-space: pre-wrap;
            line-height: 1.5;
            font-size: 1rem;
            margin-top: 5px;
            height: 85px;
            display: flex;
            align-items: center;
            justify-content: center;
            @media (max-width: 1198px) {
              height: 82px;
            }
            @media (max-width: 992px) {
              font-size: 0.9rem;
            }
            @media (max-width: 767px) {
              height: auto;
              font-size: 1rem;
            }
          }
          &__button {
            @include customButton;
            a {
              color: #fff;
            }
          }
          @media (max-width: 767px) {
            margin-bottom: 40px;
          }
        }
        @media (max-width: 767px) {
          padding-bottom: 0;
        }
      }
    }
    .modeling-example {
      padding-bottom: 30px;
      a {
        text-decoration: none;
        display: block;
        color: #fff;
      }
      .title-tab {
        max-width: unset;
        width: 400px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        div {
          margin-left: 20px;
          color: $mainColor;
          background-color: #fff;
          padding: 5px 20px;
        }
      }
      .example-card {
        width: 100%;
        padding: 40px;
        &__img {
          width: 90%;
          padding: 40px;
          margin: 0 auto;
          border-radius: 50%;
          border: 4px solid $mainColor;
          img {
            width: 100%;
          }
        }
        &__intro {
          margin: auto 0;
          padding: 0;
          &__title {
            text-align: center;
            font-size: 2rem;
            color: $mainColor;
            font-weight: 700;
          }
          &__content {
            font-size: 1.3rem;
            font-weight: 700;
            color: $mainColor;
            line-height: 1.5;
            padding: 20px 80px;
          }
          &__button {
            @include customButton;
          }
        }
      }
    }
  }
  .home-video {
    padding-bottom: 60px;
    &__title {
      font-size: 3rem;
      font-weight: 700;
      text-align: center;
      margin: 40px auto;
      word-break: keep-all;
      line-height: 1.2;
      @media (max-width: 515px) {
        font-size: 2.5rem;
      }
      @media (max-width: 428px) {
        font-size: 2rem;
      }
    }
    &__sub-title {
      font-size: 1.5rem;
      font-weight: 700;
      text-align: center;
      margin: 24px auto;
      word-break: keep-all;
      line-height: 1.2;
      @media (max-width: 428px) {
        font-size: 1.2rem;
      }
    }
  }
  .use‑situation-container {
    border-left: 3px solid $mainColor;
    padding: 60px 0 50px 50px;
    .use‑situation-card {
      min-height: 306px;
      position: relative;
      margin-right: 50px;
      margin-bottom: 100px;
      &__topic {
        border-radius: 5px;
        background-color: $subLightColor;
        font-weight: bold;
        font-size: 24px;
        color: $mainColor;
        padding: 10px 30px;
      }
      &__content {
        display: flex;
        padding-top: 30px;
        padding-left: 30px;
      }
      &__text-area {
        width: 60%;
        color: $subColor;
        &__title {
          font-weight: bold;
          font-size: 18px;
          margin-bottom: 10px;
        }
        &__content {
          line-height: 1.5;
          margin-bottom: 20px;
        }
      }
      &__img-area {
        position: absolute;
        right: -50px;
        bottom: -30px;
        img {
          width: 180px;
        }
      }
    }
  }
  .pricing-page {
    padding-top: 0;
    padding-bottom: 60px;
    .title-tab {
      margin-left: -45px !important;
    }
    .pricing-topic {
      padding: 0;
    }
    .price-intro {
      &__bg {
        &__content {
          padding: 0;
          .intro-detail {
            margin-left: -45px;
          }
        }
      }
    }
  }
  @include pad {
    .banner {
      &__title {
        padding-top: 30px;
        h2 {
          font-size: 24px;
        }
        .try-btn {
          width: 100%;
        }
      }
      // &__img {
      //   img {
      //     width: 140%;
      //     margin-left: -40px;
      //   }
      // }
    }
    .main-content {
      .product-feature {
        .feature-card-container {
          .feature-card {
            &__title {
              font-size: 1.3rem;
            }
            &__content {
              font-size: 1rem;
            }
          }
        }
      }
      .product-application {
        .theme-card {
          padding: 40px 20px;
          &__info {
            &__title {
              font-size: 1.4rem;
              margin-bottom: 10px;
            }
            &__subtitle {
              font-size: 1rem;
              margin-bottom: 10px;
            }
            &__content {
              margin-bottom: 10px;
            }
          }
        }
      }
      .modeling-example {
        .example-card {
          &__img {
            width: 100%;
          }
          &__intro {
            &__content {
              padding: 20px 30px;
            }
          }
        }
      }
    }
    .use‑situation-container {
      padding: 60px 0 100px 30px;
      .use‑situation-card {
        &__topic {
          font-size: 16px;
          padding: 10px 20px;
        }
        &__content {
          padding-top: 20px;
          padding-left: 20px;
          font-size: 14px;
        }
        &__text-area {
          &__title {
            font-size: 16px;
            line-height: 1.5;
          }
        }
        &__img-area {
          img {
            width: 150px;
          }
        }
      }
    }
    .pricing-page {
      background-color: #fff;
      .title-tab {
      }
      .pricing-topic {
      }
      .price-intro {
        &__bg {
          &__content {
            padding: 10px;
            .intro-detail {
              margin-left: -25px;
            }
          }
        }
      }
    }
  }
  @include mobile {
    .title-tab {
      width: 100% !important;
      max-width: unset;
      border-radius: 0;
      margin: 0 auto;
      font-size: 20px;
      padding: 20px;
    }
    .banner-row {
      justify-content: flex-start;
    }
    .banner {
      padding: 30px 0;
      &__title {
        padding: 0;
        h2 {
          font-size: 1.6rem;
          margin-bottom: 10px;
          justify-content: center;
          span {
            display: unset;
          }
        }
        p {
          font-size: 1rem;
          text-align: center;
        }
        &__bottom {
          margin: 0;
          margin-top: 10px;
        }
      }
      &__img {
        display: none;
      }
    }
    .main-content {
      border-left: unset;
      .product-feature {
        padding-top: 30px;
        .feature-card-container {
          padding: 0;
          padding-bottom: 20px;
          .feature-card {
            width: 100%;
            margin-bottom: 20px;
          }
        }
      }
      .product-application {
        .theme-card {
          padding: 0;
          margin: 30px 0;
          &__info {
            &__title {
              font-size: 1.3rem;
            }
            &__subtitle {
              font-size: 1rem;
            }
          }
        }
      }
      .modeling-example {
        .title-tab {
          div {
            width: 100%;
            margin-left: 0;
            margin-top: 20px;
          }
        }
        .example-card {
          padding: 30px 10px;
          &__img {
            width: 60%;
            margin-bottom: 20px;
          }
          &__intro {
            &__title {
              font-size: 1.6rem;
            }
            &__content {
              font-size: 1rem;
              padding: 20px 10px;
            }
          }
        }
      }
    }
    .use‑situation-container {
      padding: 30px 0 50px 0;
      border-left: unset;
      .use‑situation-card {
        padding: 10px;
        margin: 0 auto;
        margin-bottom: 50px;
        &__topic {
          font-size: 14px;
          padding: 10px 20px;
        }
        &__content {
          padding-top: 20px;
          padding-left: 20px;
          font-size: 12px;
        }
        &__text-area {
          &__title {
            font-size: 14px;
            line-height: 1.5;
          }
        }
        &__img-area {
          right: 15px;
          bottom: -20px;
          img {
            width: 130px;
          }
        }
      }
    }
    .pricing-page {
      background-color: #fff;
      .title-tab {
        margin: 0 auto !important;
        font-size: 20px;
      }
      .price-intro {
        &__bg {
          &__content {
            .intro-detail {
              margin-left: 0;
            }
          }
          .plan-card-container {
            justify-content: space-between;
            .plan-card {
              width: 32%;
            }
          }
        }
      }
      .pricing-topic {
        &__guide {
          border-left: unset;
        }
      }
    }
  }
}
