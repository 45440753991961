.WebMap_section {
  @extend .mainContainer;
  .WebMap_body {
    @extend .mainBody;
    ul {
      @include flex_func(center, flex-start);
      width: 100%;
      margin: 5vw 0;
      li {
        img {
          width: 20%;
        }
        h3 {
          margin: 0;
        }
        a {
          margin: 2vh 0;
          color: #ccc;
          text-decoration: none;
        }
        @include flex_func(center, flex-start, column);
        width: calc(100% / 6);
        list-style: none;
      }
    }
  }
}
