.use-case-sub-page {
  a {
    display: block;
    text-decoration: none;
    color: #fff;
  }
  h3 {
    font-size: 1.8rem;
    font-weight: 700;
    color: $mainColor;
    text-align: center;
    margin-bottom: 30px;
    line-height: 1.5;
  }
  &__banner {
    padding: 100px 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    color: #fff;
    text-shadow: 0 0 10px rgba(44, 44, 44, 0.561);
    &__theme {
      font-size: 2.5rem;
      font-weight: 700;
      margin-bottom: 10px;
      padding: 0;
    }
    &__title {
      font-size: 2rem;
      font-weight: 700;
      margin-bottom: 10px;
      padding: 0;
    }
    &__subtitle {
      font-size: 1.2rem;
      line-height: 1.5;
      padding: 0;
      margin-bottom: 20px;
      font-weight: 700;
    }
    &__button {
      @include customButton;
      margin: 0;
    }
  }
  .case-hook {
    background-color: $subLightColor;
    padding: 30px 0;
    &__card {
      text-align: center;
      img {
        height: 150px;
      }
      h4 {
        font-size: 1.3rem;
        font-weight: 700;
        line-height: 1.5;
        margin: 15px 0;
        color: $mainColor;
        white-space: pre-wrap;
      }
      p {
        color: $subColor;
        line-height: 1.5;
        padding: 0 60px;
      }
    }
  }
  .solution-container {
    background-color: #fff;
    padding: 30px 0;
    .solution-card {
      &__topic {
        font-size: 1.5rem;
        font-weight: 700;
        margin-bottom: 10px;
        color: $mainColor;
        text-align: center;
      }
      &__text-area {
        margin: auto 0;
        padding: 40px;
      }
      &__content {
        line-height: 1.5;
      }
      &__button {
        line-height: 1;
        @include customButton;
        margin-top: 20px;
      }
      .video-player {
        width: 100% !important;
        height: 300px !important;
      }
      &::after {
        content: '';
        width: 100%;
        height: 3px;
        margin: 40px 0;
        background-image: linear-gradient(
          to right,
          #ccc 0%,
          #ccc 50%,
          transparent 50%
        );
        background-size: 20px 3px;
        background-repeat: repeat-x;
      }
      &__video {
        img {
          width: 100%;
          border-radius: 5px;
        }
      }
    }
    .solution-card:last-child {
      &::after {
        content: none;
      }
    }
  }
  .client-example {
    padding: 30px 0;
    background-color: $subLightColor;
    &__card {
      margin-bottom: 60px;
      &__img {
        width: 100%;
        max-height: 300px;
        img {
          width: 90%;
          height: 100%;
          object-fit: cover;
          border-radius: 5px;
          overflow: hidden;
        }
      }
      &__text-area {
        line-height: 1.5;
        margin: auto 0;
        padding: 30px;
        .industry-category {
          font-size: 1.5rem;
          font-weight: 700;
          color: $mainColor;
        }
        &__subtitle {
          font-size: 1.5rem;
          font-weight: 700;
          margin-bottom: 15px;
        }
        &__button {
          @include customButton;
          margin-top: 20px;
        }
      }
    }
  }
  .product-strength {
    background-color: $mainColor;
    padding: 30px 0;
    color: $mainColor;
    &__title {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 30px;
      h3 {
        height: 50px;
        padding: 0 80px;
        background-color: #fff;
        margin-bottom: 0;
        line-height: 1.75;
      }
      .triangle-left {
        width: 0;
        height: 0;
        border: 25px solid;
        border-color: transparent #fff transparent transparent;
      }
      .triangle-right {
        width: 0;
        height: 0;
        border: 25px solid;
        border-color: transparent transparent transparent #fff;
      }
    }
    &__card {
      background-color: #fff;
      padding: 20px 30px;
      margin: 0 15px;
      &__title {
        font-size: 1.3rem;
        font-weight: 700;
        margin-bottom: 15px;
        text-align: center;
      }
      &__content {
        line-height: 1.5;
      }
    }
  }
  .link-to-other-page {
    padding: 30px 0;
    background-color: #fff;
  }
  .call-action {
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center 40%;
    padding: 60px 0;
    &__text {
      width: 80%;
      margin: 0 auto;
    }
    &__title {
      font-size: 2rem;
      font-weight: 700;
      text-align: center;
      color: #fff;
      padding-top: 40px;
      text-shadow: 3px 3px 10px rgba(0, 0, 0, 0.603);
    }
    &__subtitle {
      font-size: 1.8rem;
      font-weight: 700;
      color: #fff;
      margin-top: 20px;
      text-align: center;
      text-shadow: 3px 3px 10px #000;
    }
    .call-action-btn-container {
      display: flex;
      justify-content: center;
      margin-top: 60px;
      align-items: center;
    }
    &__btn {
      @include customButton;
      margin: 0 20px;
    }
    &__btn.go-contact {
      background-color: #545ba8;
      box-shadow: 3px 3px 0 #545ba8;
      border-color: lighten(#545ba8, 30%);
      &:hover {
        background-color: lighten(#545ba8, 10%);
      }
    }
  }
  @include pad {
    .case-hook {
      &__card {
        p {
          padding: 0 15px;
        }
      }
    }
    .solution-container {
      .solution-card {
        &__topic {
          font-size: 1.3rem;
        }
        &__video {
          padding: 0;
        }
        .video-player {
          height: 260px !important;
        }
        &::after {
          margin: 20px 0;
        }
      }
    }
    .client-example {
      padding: 30px 0;
      background-color: $subLightColor;
      &__card {
        &__text-area {
          .industry-category {
            font-size: 1.3rem;
          }
          &__subtitle {
            font-size: 1.3rem;
            margin-bottom: 10px;
          }
        }
      }
    }
    .product-strength {
      &__card {
        padding: 20px 10px;
        margin: 0 10px;
      }
    }
  }
  @include mobile {
    &__banner {
      padding: 30px 0;
      background-position: inherit;
      text-shadow: 0 0 10px rgb(10, 10, 10);
      &__theme {
        font-size: 1.8rem;
      }
      &__title {
        font-size: 1.4rem;
      }
      &__subtitle {
        font-size: 1rem;
      }
    }
    h3 {
      font-size: 1.4rem;
    }
    .case-hook {
      &__card {
        margin-bottom: 20px;
      }
    }
    .solution-container {
      h3 {
        margin-bottom: 20px;
      }
      .solution-card {
        &__video {
          padding: 0 10px;
        }
        &__text-area {
          padding: 20px 15px;
        }
        .video-player {
          height: 230px !important;
        }
        &::after {
          content: none;
        }
      }
    }
    .client-example {
      &__card {
        margin-bottom: 0;
        &__img {
          max-height: 230px;
          margin-bottom: 20px;
          img {
            width: 100%;
            max-height: 230px;
          }
        }
        &__text-area {
          padding-top: 0;
          .industry-category {
            text-align: center;
          }
          &__subtitle {
            text-align: center;
          }
        }
      }
    }
    .product-strength {
      &__title {
        h3 {
          padding: 0 20px;
          line-height: 2.5;
          font-size: 1.3rem;
        }
      }
      &__card {
        margin-bottom: 20px;
      }
    }
    .call-action {
      padding: 30px 0;
      &__text {
        width: 100%;
        padding: 0 10px;
      }
      &__title {
        font-size: 1.1rem;
      }
      &__subtitle {
        font-size: 1.1rem;
        margin-top: 10px;
      }
      .call-action-btn-container {
        margin-top: 30px;
      }
    }
  }
}
