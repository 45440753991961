.add-account-modal {
  .add-account-form {
    .btn {
      @include customButton;
      padding: 5px 8px;
      width: fit-content;
      height: fit-content;
      margin: 0;
      font-size: 0.9rem;
    }
    .btn.use-user-name {
      @media (max-width: 455px) {
        font-size: 0.8rem;
      }
    }
    .edit-data-accept {
      width: 60%;
      margin: 0 auto;
      margin-top: 30px;
      .edit-data-btn {
        @include customButton;
        padding: 5px 10px;
        font-size: 1rem;
        width: 100%;
        margin: 0 auto;
      }
    }
    .properties {
      padding-left: 0;
      .property {
        .input-container {
          // width: calc(100% - 106px);
          display: flex;
          align-items: center;
          justify-content: space-between;
          column-gap: 8px;
        }
      }
    }
    .checkbox-container {
      align-items: center;
      span {
        cursor: pointer;
      }
    }
  }
  .modal-dialog .modal-content .modal-footer {
    border-top: 1px solid #dee2e6;
  }
}
