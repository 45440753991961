.partner-intro {
  padding: 30px 0;
  background-color: #fff;
  &__title {
    font-size: 3rem;
    font-weight: 700;
    text-align: center;
    margin: 40px auto;
    line-height: 1.2;
    @media (max-width: 538px) {
      font-size: 2.5rem;
    }
    @media (max-width: 448px) {
      white-space: pre-wrap;
    }
  }
  &__partner-container,
  &__customer-container {
    margin-bottom: 24px;
    &__title {
      font-size: 2rem;
      font-weight: 700;
      text-align: center;
      line-height: 1.2;
      margin: 30px 0;
      span {
        position: relative;
        cursor: pointer;
        .MuiSvgIcon-root {
          color: #5a36a1;
          font-size: 2.5rem;
          margin-right: 10px;
          position: absolute;
          left: -2.3rem;
        }
      }
    }
    &__partners,
    &__customers {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding: 0 40px;
      overflow: hidden;
      transition: all 0.8s ease-out;
      &__partner,
      &__customer {
        width: 18%;
        @media (max-width: 767px) {
          width: 48%;
        }
        img {
          width: 100%;
        }
      }
    }
  }
  &__customer-container {
    position: relative;
    &__title {
      margin-bottom: 10px;
    }
    nav.customer-type {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-bottom: 30px;
      .nav-link {
        width: 20%;
        text-align: center;
        padding-bottom: 10px;
        cursor: pointer;
        position: relative;
        font-size: 1.5rem;
        @media (max-width: 999px) {
          font-size: 1.4rem;
        }
        @media (max-width: 943px) {
          font-size: 1.3rem;
        }
        @media (max-width: 887px) {
          font-size: 1.2rem;
        }
        @media (max-width: 831px) {
          font-size: 1.1rem;
        }
        @media (max-width: 775px) {
          font-size: 1rem;
        }
        &::after {
          content: '';
          width: 0;
          height: 5px;
          background-color: lighten($mainColor, 20%);
          position: absolute;
          bottom: -2.5px;
          left: 0;
          transition: all 0.2s ease-in-out;
        }
        &:hover {
          color: $mainColor;
          &::after {
            content: '';
            width: 100%;
            color: lighten($mainColor, 20%);
          }
        }
        &--active {
          color: $mainColor;
          font-weight: bold;
          &::after {
            content: '';
            width: 100%;
            background-color: $mainColor;
          }
        }
      }
    }
    .spinner-container {
      padding: 100px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      .spinner-grow {
        width: 50px;
        height: 50px;
        background-color: $mainColor;
      }
      p {
        color: $mainColor;
        text-align: center;
        font-weight: bold;
        margin-left: 20px;
      }
    }
    .slider {
      width: 100vw;
      display: flex;
      .mobile-customer-text {
        font-size: 1.5rem;
        text-align: center;
        padding: 20px 0;
      }
      .col-12 {
        padding: 0 40px;
      }
    }
    &__customers {
      padding: 0;
    }
  }
}
