.quotation {
  width: $quotationWidth;
  height: $quotationHeight;
  font-family: 'Microsoft JhengHei';
  padding: 30px;
  font-size: 12px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: auto;
  .detail-content {
    margin-left: 10px;
  }
  .quotation-subtitle {
    color: $quotationMainColor;
    font-size: $quotationSubtitle;
    border-left: 10px solid $quotationMainColor;
    padding: 0 10px;
    margin-bottom: 20px;
  }
  &__head {
    display: flex;
    .logo {
      width: 30%;
      img {
        width: 100%;
        object-fit: contain;
      }
    }
  }
  &__title {
    margin: 15px 0;
    .title-font {
      width: 260px;
      margin-left: auto;
      font-size: $quotationTitle;
      text-align: center;
      font-weight: bold;
    }
  }
  &__info {
    width: 100%;
    display: flex;
    font-weight: bold;
    margin-bottom: 30px;
    .info-container {
      width: 30%;
      margin-right: 10px;
      color: $quotationSubColor;
      .company-detail {
        display: flex;
        margin-bottom: 10px;
        .detail-label {
          width: 60px;
          text-align: right;
          font-weight: bold;
          color: $quotationMainColor;
        }
        .detail-content {
          width: calc(100% - 100px);
        }
      }
    }
    &__detail {
      width: 260px;
      margin-left: auto;
      color: $quotationLightColor;
      height: calc(0.8rem * 2 + 30px);
      background-color: $quotationMainColor;
      -webkit-print-color-adjust: exact;
      padding: 10px 10px;
      .quotation-date,
      .quotation-num {
        margin-bottom: 10px;
      }
    }
  }
  &__list {
    width: 100%;
    margin-bottom: 30px;
    .table thead th {
      text-align: center;
      background-color: $quotationMainColor !important;
      -webkit-print-color-adjust: exact;
      color: $quotationLightColor;
      font-weight: bolder;
      -webkit-print-color-adjust: exact;
    }
    .table td {
      padding: 15px 20px;
    }
    .table tbody {
      border-bottom: 5px solid $quotationMainColor !important;
      -webkit-print-color-adjust: exact;
    }
    .table-striped tbody tr:nth-of-type(odd) {
      background-color: $quotationLightColor !important;
      -webkit-print-color-adjust: exact;
    }
    .table-bordered th,
    .table-bordered td {
      border: 1px solid $quotationLightColor !important;
    }
    .table-bottom {
      width: 13rem;
      margin-left: auto;
      text-align: right;
      font-weight: bold;
      .total-amount,
      .taxes,
      .final-count {
        width: 100%;
        padding: 0 20px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        font-size: $quotationSubtitle;
        font-weight: bolder;
      }
      .final-count {
        padding: 10px 20px;
        color: $quotationLightColor;
        background-color: $quotationMainColor;
        -webkit-print-color-adjust: exact;
      }
    }
  }
  &__foot {
    width: 100%;
    text-align: center;
    .footer-title {
      color: $quotationLightColor;
      background-color: $quotationSubColor;
      -webkit-print-color-adjust: exact;
      padding: 10px;
      font-weight: bold;
      font-size: $quotationSubtitle;
    }
    .footer-detail {
      padding: 10px;
      text-align: left;
      line-height: 1.2;
      &__top {
        display: flex;
        justify-content: space-between;
        .list-rule {
          display: flex;
          margin-bottom: 10px;
        }
        .stamp-area {
          width: 50%;
          min-height: 140px;
          border: 1px solid $quotationSubColor;
          img {
            width: 100%;
            object-fit: contain;
          }
        }
      }
    }
    &__rules {
      width: 60%;
      border: 1px solid $quotationSubColor;
    }
    &__sign {
      width: 38%;
      border: 1px solid $quotationSubColor;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .sign-date {
        height: 36px;
        font-weight: bold;
        border-top: 1px solid $quotationSubColor;
        padding: 10px;
      }
    }
  }
}
