.management-center {
  width: 100%;
  min-height: $minMainContentHeight;
  display: flex;
  position: relative;
  a {
    text-decoration: none;
  }
  .side-nav {
    width: 200px;
    min-height: $minMainContentHeight;
    background-color: $mainColor;
  }
  .page-container {
    width: calc(100% - 200px);
    background-color: $subLightColor;
  }
  @include mobile {
    .side-nav {
      width: 100%;
      min-height: unset;
      position: fixed;
      background-color: unset;
      z-index: 99;
    }
    .page-container {
      width: 100%;
      padding-left: 50px;
    }
  }
}
