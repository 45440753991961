@mixin titleTab {
  max-width: 300px;
  font-size: $tabFontSize;
  font-weight: bold;
  border-radius: 5px;
  padding: 10px;
  margin-left: -30px;
  text-align: center;
  background-color: darken($mainColor, 5%);
  color: #fff;
}

@mixin accordionCustom {
  border: 1px solid $subColor;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  user-select: none;
}

@mixin customButton {
  width: 150px;
  background-color: $mainColor;
  padding: 10px;
  color: #fff;
  text-align: center;
  font-size: 1.1rem;
  font-weight: 700;
  border-radius: 5px;
  margin: 0 auto;
  user-select: none;
  cursor: pointer;
  box-shadow: 3px 3px 0 $mainColor;
  border: 1px solid lighten($mainColor, 30%);
  transition: 0.2s ease-in-out;
  &:hover {
    background-color: lighten($mainColor, 10%);
  }
}
