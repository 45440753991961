.custom-carousel-multiple {
  img {
    // width: 360px;
    width: 100%;
    height: 200px;
    object-fit: cover;
    object-position: center center;
    border-radius: 5px;
  }

  .swiper-container {
    width: 100%;
    height: 200px;
    position: relative;
    .swiper-button-next {
      color: #fff;
    }
    .swiper-button-prev {
      color: #fff;
    }
    .swiper-button-disabled {
      opacity: 0.2;
    }
    .swiper-slide {
      position: relative;
      .swiper-btn {
        @include customButton;
        position: absolute;
        bottom: 20px;
        left: calc(50% - 75px);
      }
      .swiper-btn.disabled-btn {
        background-color: #666666;
        box-shadow: 3px 3px 0 #666666;
        border-color: #ccc;
      }
    }
    // .coming-soon-text {
    //   width: 100%;
    //   color: #fff;
    //   font-weight: 700;
    //   text-align: center;
    //   position: absolute;
    //   font-size: 2rem;
    //   top: 40%;
    //   z-index: 100;
    //   opacity: 0;
    //   transition: 0.3s;
    //   user-select: none;
    // }
    // .link-not-done {
    //   transition: 0.4s all;
    //   &:hover {
    //     .coming-soon-text {
    //       opacity: 1;
    //     }
    //     img {
    //       filter: brightness(0.3);
    //     }
    //   }
    // }
  }
  // @include pad {
  //   .swiper-container {
  //     .coming-soon-text {
  //       padding: 0 30px;
  //       top: 25%;
  //     }
  //   }
  // }
  // @include mobile {
  //   .swiper-container {
  //     .coming-soon-text {
  //       padding: 0 60px;
  //     }
  //   }
  // }
}
