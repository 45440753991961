.loginContainer {
  @extend .mainContainer;
  @extend .main-content;
  background: linear-gradient(45deg, #545ba8 40%, $mainColor 60%);
  .visibility-icon {
    position: absolute;
    top: 30%;
    right: 15px;
    user-select: none;
    cursor: pointer;
  }
  .visibility-off-icon {
    color: #a8a6a6;
  }
  .forget-pwd {
    margin-top: 10px;
    font-size: 0.8rem;
    user-select: none;
    cursor: pointer;
    color: $quotationMainColor;
    &:hover {
      color: darken($quotationMainColor, 10%);
    }
  }
  @include flex_func();
  .loginInput {
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 10px;
    max-width: 360px;
    min-height: 340px;
    display: flex;
    flex-flow: column nowrap;
    row-gap: 32px;
    padding: 36px 30px 18px;
    margin: 0 10px;
    h3 {
      text-align: center;
      color: rgb(65, 115, 165);
    }
    .loginLogo {
      @include flex_func(center);
    }
    .loginBtn {
      @include flex_func();
      button {
        width: 80%;
        background-color: $subPurple;
      }
    }
  }
}
.forget-pwd-modal,
.change-pwd-modal {
  width: 100%;
  margin: 0 auto;
  .modal-dialog {
    max-width: 400px;
  }
  .form-label {
    color: $subColor;
    font-size: 14px;
    margin-bottom: 5px;
    padding-left: 5px;
    border-left: 5px solid $mainColor;
  }
  &__body {
    .hint-text {
      padding: 0;
      padding-bottom: 10px;
      text-align: center;
      color: $subColor;
      h4 {
        font-size: 1.3rem;
        font-weight: 700;
        color: $mainColor;
      }
    }
    img {
      width: 250px;
    }
  }
  .modal-footer {
    padding-top: 0;
    .modal-btn {
      min-width: 100px;
      font-size: 1rem;
      font-weight: 700;
      padding: 6px 12px;
      margin: 0 5px;
    }
    .send-forget-mail-cancel-btn {
      background-color: #a8a6a6;
      color: #fff;
    }
    .send-forget-mail-confirm-btn {
      background-color: $mainColor;
      color: #fff;
    }
    @include mobile {
      justify-content: space-between;
      .modal-btn {
        width: 48%;
        margin: 0;
      }
    }
  }
}
