.plan-intro {
  background-color: #fff;
  .content {
    padding-top: 40px;
    min-height: unset;
  }
  &__title {
    font-size: 3rem;
    font-weight: 700;
    text-align: center;
    margin: 40px auto;
    line-height: 1.2;
    @media (max-width: 991px) {
      font-size: 2.8rem;
    }
    @media (max-width: 515px) {
      font-size: 1.8rem;
    }
  }
  &__sub-title {
    font-size: 2rem;
    font-weight: 500;
    text-align: center;
    margin: 24px auto 36px auto;
    line-height: 1.2;
    @media (max-width: 450px) {
      font-size: 1.4rem;
    }
  }
  &__sub-title.more-product {
    span:nth-child(2) {
      font-weight: 700;
    }
  }
  &__key-vision {
    display: flex;
    @media (max-width: 991px) {
      flex-direction: column;
    }
    .img-container {
      display: flex;
      align-items: center;
      img {
        width: 100%;
      }
    }
    &__title {
      font-size: 2.5rem;
      margin-bottom: 20px;
      word-break: keep-all;
      font-weight: 700;
      text-align: center;
      line-height: 1.2;
      white-space: pre-wrap;
      margin-top: 40px;
      @media (max-width: 495px) {
        font-size: 2rem;
      }
      @media (max-width: 429px) {
        margin-top: 0;
        font-size: 1.6rem;
      }
    }
    &__content {
      margin: 40px auto;
      white-space: pre-wrap;
      font-size: 1.2rem;
      line-height: 1.4;
      @media (max-width: 991px) {
        font-size: 1.6rem;
      }
      @media (max-width: 767px) {
        font-size: 1.3rem;
      }
      @media (max-width: 537px) {
        font-size: 1.2rem;
      }
    }
    &__checkboxItem {
      font-size: 1.2rem;
      font-weight: 700;
      &__item {
        display: flex;
        margin-bottom: 10px;
        div.text {
          display: flex;
          align-items: center;
          margin-left: 5px;
          line-height: 1.2;
          @media (max-width: 991px) {
            font-size: 1.4rem;
          }
          @media (max-width: 537px) {
            font-size: 1.2rem;
          }
          @media (max-width: 400px) {
            font-size: 1rem;
          }
        }
      }
    }
  }
  &__introduce {
    &__editor {
      &__ta {
        &__container {
          display: flex;
          text-align: center;
          padding-bottom: 40px;
          .left,
          .right {
            &__content {
              background-color: #f7f7f7;
              border-radius: 10px;
              padding-top: 20px;
              height: 100%;
              &__title {
                white-space: pre-wrap;
                font-size: 1.8rem;
                text-align: center;
                margin-bottom: 20px;
                word-break: keep-all;
                line-height: 1.2;
                span {
                  font-weight: 700;
                }
                @media (max-width: 991px) {
                  font-size: 1.3rem;
                }
                @media (max-width: 767px) {
                  font-size: 1.8rem;
                }
                @media (max-width: 430px) {
                  font-size: 1.6rem;
                }
                @media (max-width: 390px) {
                  font-size: 1.4rem;
                }
              }
              &__question-container {
                display: flex;
                justify-content: space-around;
                &__question {
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  padding: 0;
                  img {
                    width: 90%;
                    border-radius: 50%;
                  }
                  &__text {
                    white-space: pre-wrap;
                    line-height: 1.2;
                    padding: 40px 0 20px 0;
                    span:nth-child(2) {
                      font-weight: 700;
                    }
                    @media (max-width: 1199px) {
                      font-size: 0.85rem;
                    }
                    @media (max-width: 991px) {
                      font-size: 0.7rem;
                    }
                    @media (max-width: 767px) {
                      font-size: 1rem;
                    }
                    @media (max-width: 669px) {
                      font-size: 0.85rem;
                    }
                    @media (max-width: 488px) {
                      font-size: 0.75rem;
                    }
                  }
                }
              }
            }
          }
          @media (max-width: 767px) {
            flex-direction: column;
            .left {
              padding-bottom: 20px;
            }
          }
        }
      }
      &__cloud,
      &__lease {
        &__container {
          display: flex;
          .left {
            img {
              width: 100%;
            }
          }
          .right {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            &__content-container {
              display: flex;
              flex-direction: column;
              margin-top: 90px;
              &__content {
                margin-bottom: 40px;
                &__title {
                  font-size: 2.5rem;
                  margin-bottom: 20px;
                  word-break: keep-all;
                  font-weight: 700;
                  @media (max-width: 495px) {
                    font-size: 2rem;
                  }
                  @media (max-width: 429px) {
                    font-size: 1.6rem;
                  }
                }
                &__text {
                  white-space: pre-wrap;
                  font-size: 1.2rem;
                  line-height: 1.4;
                  span:nth-child(even) {
                    font-weight: 700;
                  }
                  @media (max-width: 1199px) {
                    font-size: 1rem;
                  }
                  @media (max-width: 991px) {
                    font-size: 1.2rem;
                  }
                  @media (max-width: 767px) {
                    font-size: 1.1rem;
                  }
                  @media (max-width: 536px) {
                    font-size: 1rem;
                  }
                  @media (max-width: 495px) {
                    font-size: 0.9rem;
                  }
                }
                &__service-container {
                  display: flex;
                  justify-content: space-around;
                  &__service {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    padding: 0;
                    img {
                      width: 100%;
                    }
                    &__text {
                      text-align: center;
                      @media (max-width: 463px) {
                        font-size: 0.9rem;
                      }
                    }
                  }
                }
              }
              @media (max-width: 991px) {
                margin-top: 0;
              }
            }
            &__button-container {
              display: flex;
              justify-content: space-between;
              &__button {
                @include customButton;
                width: 200px;
                a {
                  color: #fff;
                }
              }
              &__button.pricing {
                width: 220px;
              }
              @media (max-width: 509px) {
                flex-direction: column;
                &__button {
                  width: 220px;
                }
                &__button:not(.pricing) {
                  margin-bottom: 10px;
                }
              }
            }
          }
          @media (max-width: 991px) {
            flex-direction: column;
          }
        }
      }
    }
    &__solution {
      &__container {
        display: flex;
        .left {
          img {
            width: 100%;
          }
        }
        .right {
          &__content-container {
            display: flex;
            flex-direction: column;
            margin-top: 90px;
            &__content {
              margin-bottom: 40px;
              &__title {
                font-size: 2.5rem;
                margin-bottom: 40px;
                word-break: keep-all;
                font-weight: 700;
                white-space: pre-wrap;
                line-height: 1.2;
                @media (max-width: 495px) {
                  font-size: 2rem;
                }
                @media (max-width: 429px) {
                  font-size: 1.6rem;
                }
              }
              &__text {
                white-space: pre-wrap;
                font-size: 1.2rem;
                line-height: 1.4;
                @media (max-width: 1199px) {
                  font-size: 1rem;
                }
                @media (max-width: 991px) {
                  font-size: 1.2rem;
                }
                @media (max-width: 767px) {
                  font-size: 1.1rem;
                }
                @media (max-width: 536px) {
                  font-size: 1rem;
                }
                @media (max-width: 495px) {
                  font-size: 0.9rem;
                }
              }
              &__plan-container {
                display: flex;
                justify-content: space-around;
                &__plan {
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  padding: 0;
                  img {
                    width: 100%;
                  }
                  &__text {
                    text-align: center;
                    @media (max-width: 463px) {
                      font-size: 0.9rem;
                    }
                  }
                }
              }
            }
            @media (max-width: 991px) {
              margin-top: 0;
            }
          }
          &__button-container {
            display: flex;
            justify-content: space-between;
            margin-top: 40px;
            &__button {
              @include customButton;
              width: 200px;
              a {
                color: #fff;
              }
            }
            .left {
              margin: 0;
              @media (max-width: 991px) {
                margin: 0 auto;
              }
            }
            .illustrate {
              width: 220px;
            }
            @media (max-width: 991px) {
              margin-top: 0;
            }
            @media (max-width: 509px) {
              flex-direction: column;
              .illustrate {
                margin-bottom: 10px;
              }
            }
          }
        }
        @media (max-width: 991px) {
          flex-direction: column;
        }
      }
    }
    &__tool {
      &__container {
        display: flex;
        .left {
          img {
            width: 100%;
          }
        }
        .right {
          &__content-container {
            display: flex;
            flex-direction: column;
            margin-top: 90px;
            &__content {
              margin-bottom: 40px;
              &__title {
                font-size: 2.5rem;
                margin-bottom: 40px;
                word-break: keep-all;
                font-weight: 700;
                white-space: pre-wrap;
                line-height: 1.2;
                @media (max-width: 495px) {
                  font-size: 2rem;
                }
                @media (max-width: 429px) {
                  font-size: 1.6rem;
                }
              }
              &__text {
                white-space: pre-wrap;
                font-size: 1.2rem;
                line-height: 1.4;
                @media (max-width: 1199px) {
                  font-size: 1rem;
                }
                @media (max-width: 991px) {
                  font-size: 1.2rem;
                }
                @media (max-width: 767px) {
                  font-size: 1.1rem;
                }
                @media (max-width: 536px) {
                  font-size: 1rem;
                }
                @media (max-width: 495px) {
                  font-size: 0.9rem;
                }
              }
              &__tool-container {
                display: flex;
                justify-content: space-around;
                margin-top: 40px;
                &__tool {
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  padding: 0;
                  img {
                    width: 100%;
                  }
                  &__text {
                    text-align: center;
                    white-space: pre-wrap;
                    line-height: 1.2;
                    @media (max-width: 463px) {
                      font-size: 0.9rem;
                    }
                  }
                }
              }
            }
            @media (max-width: 991px) {
              margin-top: 0;
            }
          }
          &__button-container {
            display: flex;
            justify-content: space-between;
            margin-top: 40px;
            &__button {
              @include customButton;
              width: 200px;
              a {
                color: #fff;
              }
            }
            .left {
              margin: 0;
              @media (max-width: 991px) {
                margin: 0 auto;
              }
            }
            @media (max-width: 991px) {
              margin-top: 0;
            }
          }
        }
        @media (max-width: 991px) {
          flex-direction: column;
        }
      }
    }
  }
  &__more-product,
  &__import-process {
    &__container {
      display: flex;
      flex-wrap: wrap;
      padding-bottom: 60px;
      justify-content: space-around;
      @media (max-width: 768px) {
        padding-bottom: 0;
      }
      &__product {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 20px;
        border-radius: 5px;
        transition: 0.3s;
        @media (max-width: 768px) {
          margin-bottom: 20px;
        }
        img {
          width: 100%;
          transition: 0.5s;
          @media (max-width: 767px) {
            width: 60%;
          }
        }
        &__shadow {
          width: 35%;
          height: 30px;
          transform: rotateX(60deg);
          position: absolute;
          bottom: 60px;
          border-radius: 50%;
          transition: 0.5s;
          opacity: 0;
        }
        .editor {
          background-color: #f3634c78;
        }
        .solution {
          background-color: #0534856b;
        }
        .tool {
          background-color: #f3634c78;
          bottom: 80px;
        }
        &__title {
          display: inline-block;
          font-size: 1.6rem;
          font-weight: 700;
          padding: 5px 0px;
          margin: 20px 0;
          word-break: keep-all;
        }
        &:hover {
          img.hover-able {
            transform: translateY(-30px);
          }
          .editor,
          .solution,
          .tool {
            transform: rotateX(60deg) scaleX(2);
            opacity: 0.8;
          }
        }
      }
      &__button-container {
        display: flex;
        width: 100%;
        margin-top: 40px;
        &__button {
          @include customButton;
          width: 200px;
          a {
            color: #fff;
          }
        }
      }
      &__step {
        display: grid;
        place-items: center;
        img {
          width: 100%;
        }
        @media (max-width: 767px) {
          margin-bottom: 20px;
          img {
            width: 60%;
          }
        }
      }
    }
    &__container.tool {
      width: 75%;
      margin: auto;
      @media (max-width: 767px) {
        width: 100%;
      }
    }
    @media (max-width: 767px) {
      .more-product-card {
        margin-bottom: 20px;
        img {
          width: 40%;
        }
        .shadow {
          width: 16%;
        }
      }
      .more-product-button-container {
        margin-top: 20px;
      }
    }
  }
  &__mPoint-pricing {
    &__table {
      font-size: 1.3rem;
      @media (max-width: 768px) {
        font-size: 1rem;
      }
      @media (max-width: 437px) {
        font-size: 0.9rem;
      }
      @media (max-width: 403px) {
        font-size: 0.8rem;
      }
      @media (max-width: 368px) {
        font-size: 0.7rem;
      }
      &__head {
        display: flex;
        background-color: #e1e6ff;
        .head-title {
          padding: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          @media (max-width: 500px) {
            padding: 5px;
          }
        }
      }
      &__row {
        display: flex;
        &__td {
          padding: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          .bonus {
            color: $mainColor;
            font-weight: 700;
          }
          @media (max-width: 500px) {
            padding: 5px;
          }
        }
        .bonus-percent {
          display: flex;
          justify-content: center;
          align-items: center;
          .img-container {
            height: 32px;
            display: flex;
            justify-content: flex-end;
            padding-right: 5px;
            img {
              height: 100%;
            }
            @media (max-width: 450px) {
              padding-left: 0;
            }
          }
          .text {
            display: flex;
            justify-content: flex-start;
            padding-left: 0;
            align-items: center;
            height: 32px;
            @media (max-width: 450px) {
              padding-right: 0;
            }
          }
        }
        .unBonus-point {
          margin-right: 5px;
        }
      }
    }
    &__mPoint-heap {
      display: flex;
      img {
        width: 100%;
        transform: translate(180px, -15px);
        @media (max-width: 1370px) {
          transform: translate(160px, -15px);
        }
        @media (max-width: 1330px) {
          transform: translate(140px, -15px);
        }
        @media (max-width: 1290px) {
          transform: translate(120px, -15px);
        }
        @media (max-width: 1250px) {
          transform: translate(100px, -15px);
        }
        @media (max-width: 1042px) {
          transform: translate(80px), -15px;
        }
        @media (max-width: 780px) {
          transform: translate(60px, -15px);
        }
      }
      @media (max-width: 767px) {
        img {
          display: none;
        }
        margin-bottom: 20px;
      }
    }
    &__button-container {
      padding-bottom: 60px;
      &__button {
        @include customButton;
        width: 200px;
        a {
          color: #fff;
        }
      }
    }
  }
  &__expenses {
    &__container {
      display: flex;
      padding-bottom: 60px;
      .left {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        @media (max-width: 991px) {
          padding-bottom: 40px;
        }
      }
      .right {
        padding: 0;
      }
      &__table {
        &__row:nth-child(odd) {
          background-color: #e1e6ff;
        }
        &__row {
          display: flex;
          white-space: pre-wrap;
          align-items: center;
          text-align: center;
          padding: 10px 0;
          .td {
            padding: 0;
            line-height: 1.2;
          }
          span {
            font-weight: 700;
          }
        }
        @media (max-width: 496px) {
          font-size: 0.9rem;
        }
        @media (max-width: 453px) {
          font-size: 0.85rem;
        }
        @media (max-width: 414px) {
          font-size: 0.7rem;
        }
      }
      &__content {
        font-size: 1.4rem;
        white-space: pre-wrap;
        line-height: 1.4;
        margin-bottom: 20px;
        span:nth-child(2n + 1) {
          font-weight: 700;
        }
        @media (max-width: 1199px) {
          font-size: 1.25rem;
        }
        @media (max-width: 991px) {
          font-size: 1.6rem;
        }
        @media (max-width: 767px) {
          font-size: 1.4rem;
        }
        @media (max-width: 548px) {
          font-size: 1.3rem;
        }
        @media (max-width: 515px) {
          font-size: 1.25rem;
        }
        @media (max-width: 498px) {
          font-size: 1.2rem;
        }
        @media (max-width: 481px) {
          font-size: 1.15rem;
        }
        @media (max-width: 445px) {
          font-size: 1rem;
        }
        @media (max-width: 414px) {
          font-size: 0.92rem;
        }
        @media (max-width: 385px) {
          font-size: 0.85rem;
        }
        @media (max-width: 360px) {
          font-size: 0.8rem;
        }
      }
      &__situation-container {
        display: flex;
        &__situation {
          padding: 0;
          display: flex;
          flex-direction: column;
          align-items: center;
          img {
            width: 100%;
          }
          &__title {
            white-space: pre-wrap;
            text-align: center;
            font-size: 1.2rem;
            line-height: 1.2;
            span:nth-child(2) {
              font-weight: 700;
            }
            @media (max-width: 1200px) {
              font-size: 1.1rem;
            }
            @media (max-width: 767px) {
              font-size: 1.1rem;
            }
            @media (max-width: 445px) {
              font-size: 0.9rem;
            }
            @media (max-width: 381px) {
              font-size: 0.8rem;
            }
          }
        }
      }
      @media (max-width: 991px) {
        flex-direction: column;
      }
    }
    &__button-container {
      padding-top: 40px;
      &__button {
        @include customButton;
        width: 200px;
        a {
          color: #fff;
        }
      }
    }
  }
  &__q-and-a {
    padding-top: 0 !important;
    padding-bottom: 40px;
    &__container {
      padding-bottom: 20px;
      &__question,
      &__answer {
        display: flex;
        margin-bottom: 5px;
        span {
          font-size: 2rem;
          line-height: 1.3;
        }
        .arrow-icon {
          font-size: 3rem;
          cursor: pointer;
          transform: translateY(-3px);
        }
        @media (max-width: 767px) {
          span {
            font-size: 1.4rem;
          }
          .arrow-icon {
            font-size: 2rem;
            transform: translateY(0);
          }
        }
      }
      &__question {
        span {
          cursor: pointer;
        }
      }
      &__answer {
        white-space: pre-wrap;
        overflow: hidden;
        transition: all 0.8s ease-out;
        .arrow-icon {
          opacity: 0;
        }
        span {
          line-height: 1.3;
          padding-top: 10px;
          &:nth-child(3) {
            background-color: #e1e6ff;
            padding: 10px;
            border-radius: 10px;
          }
        }
      }
    }
  }
  @media (max-width: 576px) {
    padding: 0 10px;
  }
}
@keyframes bounce {
  0% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(40px);
  }
}
