.device-binding,
.member-order-page,
.point-record-page,
.point-transfer-record,
.team-members,
.host-info,
.team-list {
  width: 95%;
  margin: 0 auto;
  min-height: $minMainContentHeight;
  position: relative;
  .MuiToolbar-gutters {
    padding-left: 0;
  }
  .table-container {
    padding: 60px 10px;
    h2.table-title {
      text-align: center;
      font-size: 1.1rem;
      background-color: $mainColor;
      padding: 0.5rem;
      color: #fff;
      border-radius: 5px 5px 0 0;
    }
  }
  .MuiTableSortLabel-root {
    flex-direction: unset;
  }
  .MuiTableRow-head {
    th {
      font-weight: bold;
      background-color: $subLightColor;
      border-color: transparent;
    }
    th:nth-child(1) {
      border-radius: 5px 0 0 5px;
    }
    th:nth-last-child(1) {
      border-radius: 0 5px 5px 0;
    }
  }
  tbody {
    td {
      padding: 8px 16px;
      border-color: $subLightColor;
    }
  }
  .MuiPaper-root {
    padding: 0 20px;
    border-radius: 0 0 5px 5px;
  }
  .MuiToolbar-root {
    h6 {
      font-size: 1rem;
    }
  }
  .status-circle {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    margin: 0 auto;
  }
  .status-circle.online {
    background-color: #1ea80b;
  }
  .status-circle.offline {
    background-color: #a8a8a8;
  }
  @include pad {
    .MuiTableRow-head {
      th {
        min-width: 105px;
        padding: 10px;
      }
    }
    tbody {
      td {
        padding: 0px;
      }
    }
    .MuiPaper-root {
      padding: 10px;
    }
    .MuiTableRow-root {
      .MuiTableCell-body {
        font-size: 14px !important;
      }
    }
    .MuiToolbar-root {
      justify-content: flex-end;
      h6 {
        font-weight: 700;
        overflow: unset !important;
        white-space: pre-line !important;
        font-size: 1rem;
      }
      .MTableToolbar-spacer-7 {
        display: none;
      }
      .MTableToolbar-actions-8 {
        width: 50px;
      }
      .MuiTextField-root {
        width: 220px;
        padding-left: 0;
      }
    }
  }
  @include mobile {
    width: 100%;
    .MuiPaper-root {
      .MuiToolbar-root {
        justify-content: space-evenly;
        padding-right: 0;
        h6.MuiTypography-root.MuiTypography-h6 {
          font-size: 1rem;
          font-weight: 700;
          line-height: unset;
        }
        .MTableToolbar-spacer-7 {
          display: none;
        }
        .MTableToolbar-spacer-15 {
          flex: unset;
        }
        .MuiTextField-root {
          width: calc(100% - 100px);
        }
      }
    }
    .MuiTableRow-head {
      th {
        padding: 5px;
        text-align: center;
      }
    }
    tbody {
      td {
        min-width: 80px;
        padding-right: 10px;
        text-align: center;
      }
    }
    .MuiTableRow-root {
      .MuiTableCell-body {
        font-size: 12px !important;
      }
    }
    .MuiTableHead-root {
      .MuiTableRow-head {
        .MuiTableCell-head {
          min-width: 100px;
          font-size: 12px;
        }
      }
    }
  }
}
.team-members {
  @include pad {
    .MuiPaper-root {
      .MuiToolbar-root {
        .MTableToolbar-actions-8 {
          width: 100px;
        }
      }
    }
  }
}
.loading-display {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 25vh;
  h2 {
    color: #333333;
    font-size: 2rem;
    text-align: center;
    font-weight: bold;
    margin-bottom: 20px;
  }
  .spinner-container {
    margin: 0 auto;
    .spinner-border {
      width: 5rem;
      height: 5rem;
    }
  }
}
