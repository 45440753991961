.example-page {
  .banner {
    color: #f7f7fb;
    padding: 200px 110px;
    background-image: url('../../assets/img/use-case-background.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    margin-bottom: 60px;
    &__title {
      font-size: 1.8rem;
      font-weight: bold;
      line-height: 1.2;
      text-shadow: 3px 3px 3px rgba(0, 0, 0, 0.678);
    }
    &__subtitle {
      font-size: 1.5rem;
      text-shadow: 3px 3px 3px #000;
    }
    &__try-btn {
      @include customButton;
      width: $customBtnWidth;
      border-bottom-color: darken($mainColor, 15%);
      border-right-color: darken($mainColor, 15%);
      &:hover {
        border-top-color: lighten($mainColor, 30%);
        border-left-color: lighten($mainColor, 30%);
      }
    }
  }
  .solution {
    &__title {
      font-size: 1.2rem;
      width: 100%;
      display: flex;
      margin-bottom: 40px;
      align-items: center;
      .left-line,
      .right-line {
        width: 50%;
        height: 3px;
        background-color: $subColor;
      }
      .middle-block {
        min-width: 300px;
        text-align: center;
        background-color: #f7f7fb;
        color: #f7f7fb;
        font-weight: bold;
        border-left: 5px solid $subColor;
        border-right: 5px solid $subColor;
        padding: 0 20px;
        z-index: 99;
        &__text {
          min-width: calc(100% - 40px);
          background-color: $subColor;
          padding: 10px;
          border-radius: 5px;
        }
      }
    }
    &__video-container {
      width: 100%;
      min-height: 350px;
      padding-bottom: 40px;
      display: flex;
      justify-content: space-between;
      .video-handler {
        width: calc(100% - 640px - 20px);
        &__btn {
          @include accordionCustom;
          margin-bottom: 15px;
          color: $subColor;
          background-color: #f7f7fb;
          .btn-text {
            padding-left: 10px;
            border-left: 5px solid $subColor;
          }
          &:hover {
            background-color: $subLightColor;
            border: 1px solid transparent;
            .btn-text {
              border-left: 5px solid $mainColor;
            }
          }
          &--active {
            @include accordionCustom;
            border: 1px solid transparent;
            background-color: $mainColor;
            color: #f7f7fb;

            .btn-text {
              padding-left: 10px;
              border-left: 5px solid #f7f7fb;
            }
          }
        }
        .card {
          background-color: transparent;
          border: unset;
          .card-body {
            padding: 15px 20px 20px 20px;
            margin-top: 5px;
            max-height: 100px;
            overflow: auto;
            &::-webkit-scrollbar-track {
              margin: 10px 0;
              border-radius: 10px;
              box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
              background-color: #f5f5f5;
            }
            &::-webkit-scrollbar {
              margin: 10px 0;
              border-radius: 10px;
              width: 6px;
              background-color: #f5f5f5;
            }
            &::-webkit-scrollbar-thumb {
              margin: 10px 0;
              border-radius: 10px;
              background-color: lighten($subColor, 10%);
            }
          }
        }
      }
      .video-viewer {
        border: 1px solid $subColor;
        border-radius: 5px;
      }
    }
  }
}
@include pad {
  .example-page {
    .banner {
      padding: 60px;
      &__title {
        font-size: 1.2rem;
      }
      &__try-btn {
        width: 200px;
      }
    }
    .solution {
      &__video-container {
        flex-direction: column-reverse;
        align-items: center;
        .video-handler {
          width: 640px;
          margin-top: 20px;
        }
      }
    }
  }
}

@include mobile {
  .example-page {
    .banner {
      padding: 30px 15px;
      &__title {
        font-size: 1.2rem;
      }
      &__try-btn {
        width: 100%;
        &:hover {
          transform: unset;
          box-shadow: unset;
          background-color: unset;
        }
      }
    }
    .solution {
      padding: 0 10px;
      &__video-container {
        flex-direction: column-reverse;
        align-items: center;
        .video-handler {
          width: 100%;
          margin-top: 20px;
        }
        .video-viewer {
          width: 100% !important;
          height: 215px !important;
        }
      }
    }
  }
}
