.about-page {
  width: 100%;
  min-height: 100vh;
  padding: 60px 0;
  margin: 0 auto;
  .title-tab {
    @include titleTab;
    margin-left: 0;
  }
  .time-line-container {
    padding-left: 30px;
    .title-tab {
      margin-left: -30px;
    }
    .time-line {
      border-left: 3px solid $mainColor;
      padding: 30px 0;
      .year-event {
        margin-bottom: 60px;
        &__topic {
          height: 45px;
          border: 3px solid $mainColor;
          border-left: 0px solid transparent;
          border-radius: 0 5px 5px 0;
          display: inline-flex;
          align-items: center;
          color: $mainColor;
          background-color: #fff;
          .year {
            font-size: 30px;
            font-weight: bold;
            font-family: 'Roboto', sans-serif;
            margin-left: 30px;
          }
          .event {
            font-size: 20px;
            font-weight: bold;
            margin: 0 30px;
          }
        }
        &__content {
          background-color: $subLightColor;
          padding: 20px;
          padding-top: 42.5px;
          margin-top: -22.5px;
          border-radius: 0 5px 5px 0;
          color: $subColor;
          line-height: 1.5;
          li {
            margin-bottom: 10px;
          }
        }
      }
    }
  }
  .product-application {
    padding: 60px 0;
    .img-container {
      margin-top: 30px;
      img {
        width: 100%;
      }
    }
  }
  .brand-intro-container {
    .logo {
      width: 400px;
      margin: 0 auto;
      margin-bottom: 30px;
      img {
        width: 100%;
      }
    }
    .brand-intro {
      padding: 30px;
      color: $subColor;
      &__subtitle {
        font-weight: bold;
        margin-bottom: 10px;
        font-size: 1.1rem;
        color: $mainColor;
      }
      &__text {
        margin-bottom: 20px;
        line-height: 1.5;
      }
    }
    .icon-container {
      display: flex;
      justify-content: space-evenly;
      flex-wrap: wrap;
      .advantage-card {
        display: flex;
        flex-direction: column;
        align-items: center;
        line-height: 1.5;
        &__icon {
          max-width: 180px;
          max-height: 180px;
          padding: 20px;
          border-radius: 50%;
          background-color: $mainColor;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 20px;
          svg {
            width: 90%;
            height: 90%;
            fill: #fff;
          }
        }
        &__title {
          font-size: 30px;
          font-weight: bold;
          color: $mainColor;
          padding: 10px;
        }
      }
    }
  }
  @include pad {
    .brand-intro-container {
      .icon-container {
        .advantage-card {
          text-align: center;
          &__title {
            font-size: 20px;
          }
        }
      }
    }
  }
  @include mobile {
    padding-top: 30px;
    .title-tab {
      max-width: unset;
      width: 100%;
      border-radius: unset;
    }
    .time-line-container {
      padding: 0;
      .title-tab {
        max-width: unset;
        width: 100%;
        margin: 0;
        border-radius: unset;
      }
      .time-line {
        margin-left: 10px;
        padding-right: 10px;
        padding-bottom: 30px;
        .year-event {
          margin-bottom: 30px;
          &__topic {
            height: 35px;
            .year {
              font-size: 18px;
              margin-left: 10px;
            }
            .event {
              font-size: 16px;
              margin: 0 20px;
            }
          }
          &__content {
            font-size: 14px;
          }
        }
      }
    }
    .product-application {
      padding: 30px 0;
      .img-container {
        margin-top: 30px;
        img {
          width: 100%;
        }
      }
    }
    .brand-intro-container {
      .logo {
        width: 60%;
        img {
          width: 100%;
        }
      }
      .icon-container {
        .advantage-card {
          margin-bottom: 30px;
        }
      }
    }
  }
}
