.classes-page {
  padding-top: 30px;
  .class-title {
    font-size: 1.2rem;
    font-weight: bold;
    padding-left: 10px;
    border-left: 5px solid $mainColor;
    margin-bottom: 20px;
  }
  .class-select {
    margin-bottom: 15px;
    line-height: 1.5;
  }
}
.single-class {
  padding: 30px 0;
  .intro-item {
    display: flex;
    .topic {
      min-width: 100px;
    }
  }
  &__title {
    font-size: 1.3rem;
    margin-bottom: 30px;
    line-height: 1.5;
  }
  &__subtitle {
    @include titleTab;
    width: 200px;
    font-size: 1rem;
    margin-left: 0;
    border-radius: 5px 5px 0 0;
  }
  &__intro {
    padding: 20px;
    border: 2px solid $mainColor;
    margin-bottom: 30px;
    &__signup-btn {
      height: 40px;
      background-color: $subPurple !important;
      color: #fff !important;
      font-weight: bold !important;
      margin-top: auto !important;
      margin-left: auto !important;
      margin-right: 20px !important;
    }
  }
  &__dm {
    width: 100%;
    margin-bottom: 30px;
    border: 2px solid $mainColor;
    padding: 5px;
    img {
      width: 100%;
    }
  }
  ul,
  li {
    margin-bottom: 10px;
    line-height: 1.5;
  }
  &__remarks {
    padding: 20px;
    border: 2px solid $mainColor;
  }
  @include mobile {
    &__intro {
      &__signup-btn {
        margin-left: 20px !important;
      }
    }
  }
}
.modal-dialog {
  .modal-content {
    border: unset;
    .modal-header {
      background-color: $mainColor;
      padding: 8px 1rem;
      color: #fff;
      .close {
        padding: 8px;
        margin: 0;
        color: #fff;
      }
    }
    .modal-body {
      padding: 20px;
      white-space: pre-wrap;
      .hint-text {
        line-height: 2;
      }
    }
    .modal-footer {
      border-top: unset;
      .send-mail-btn,
      .confirm-btn {
        background-color: $subPurple;
        border-color: $subPurple;
        &:hover {
          background-color: darken($subPurple, 10%);
        }
        &:focus {
          box-shadow: 0 0 0 0.2rem rgba(187, 38, 224, 0.3);
        }
      }
    }
  }
}
