.scroll-top-button {
  width: 50px;
  height: 50px;
  background-color: rgb(142, 57, 192);
  border-radius: 50%;
  border: 3px solid #fff;
  position: fixed;
  top: 80vh;
  right: 3%;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  cursor: pointer;
  z-index: 999;
  opacity: 0.5;
  transition: 0.3s;
  svg {
    fill: #fff;
  }
  &:hover {
    opacity: 1;
  }
}
