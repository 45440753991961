@font-face {
  font-family: 'GenYoGothic';
  font-weight: normal;
  src: url('/assets/fonts/GenYoGothic-N.ttf') format('truetype');
}
@font-face {
  font-family: 'GenYoGothic';
  font-weight: 500;
  src: url('/assets/fonts/GenYoGothic-M.ttf') format('truetype');
}
@font-face {
  font-family: 'GenYoGothic';
  font-weight: 400;
  src: url('/assets/fonts/GenYoGothic-R.ttf') format('truetype');
}
@font-face {
  font-family: 'GenYoGothic';
  font-weight: bold;
  src: url('/assets/fonts/GenYoGothic-B.ttf') format('truetype');
}
@font-face {
  font-family: 'Golos-UI';
  font-weight: normal;
  src: url('/assets/fonts/Golos-UI_VF.ttf') format('truetype');
}
.t-cloud-event {
  padding: 0;
  font-family: 'Golos-UI', 'GenYoGothic';
  line-height: 1.5;
  white-space: pre-wrap;
  font-size: 18px;
  h2 {
    font-size: 55px;
    font-weight: bold;
  }
  h3 {
    font-size: 18px;
    font-weight: bolder;
    color: #fff;
    text-align: center;
  }
  h4 {
    font-size: 22px;
    font-weight: bold;
  }
  .custom-btn {
    font-size: 18px;
    border-radius: 5px;
    background-color: #164768;
    box-shadow: 3px 3px 0 #11274c;
    color: #fff;
    padding: 0 1rem;
    font-weight: bold;
    margin: 0 0.5rem;
    text-transform: none;
    &:hover {
      background-color: #123954;
    }
  }
  .banner {
    width: 100%;
    height: 29.4rem;
    background-image: url('../../assets/img/2024citd/banner.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    .topic-container {
      height: 100%;
      text-align: right;
      margin-left: auto;
      padding: 65px 20px;
      color: #fff;
      font-weight: bold;
      p {
        font-weight: 500;
        font-size: 28px;
        padding-right: 1rem;
      }
      h2 {
        padding-right: 1rem;
        letter-spacing: 3px;
      }
    }
  }
  section {
    padding: 30px 1rem;
    background-color: #fff;
    .intro-block-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .intro-block {
      margin-bottom: 30px;
      h4 {
        margin-bottom: 1rem;
      }
      p {
        font-weight: 400;
        font-size: 18px;
        margin: 0.5rem 0;
      }
      .contact-info {
        display: flex;
        align-items: center;
        img {
          width: 55px;
          aspect-ratio: 1;
          margin-right: 0.5rem;
        }
      }
    }
    .intro-block.dm-sm {
      display: none;
    }
    .step-block-container {
      .step-block {
        position: relative;
        color: #fff;
        &__info {
          top: 10px;
          width: calc(100% - 2rem);
          height: calc(100% - 2rem);
          text-align: center;
          position: absolute;
          z-index: 10;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: center;
          &__text-container {
            text-align: left;
            span:nth-child(2),
            span:nth-child(4) {
              font-size: 18px;
              font-weight: bold;
            }
          }
        }
      }
    }
    table {
      text-align: center;
      font-size: 16px;
      th,
      td {
        padding: 1rem;
        border: 1px solid #333;
        min-width: 8rem;
      }
    }
  }
  .process {
    &__container {
      display: flex;
      .step-block {
        position: relative;
        padding: 0;
        display: flex;
        justify-content: center;
        img {
          width: 100%;
        }
        &__info {
          position: absolute;
          top: 0;
          color: #fff;
          height: 100%;
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: center;
          &__text-container {
            text-align: left;
            span:nth-child(2),
            span:nth-child(4) {
              font-size: 18px;
              font-weight: bold;
            }
          }
          h3 {
            font-size: 18px !important;
          }
        }
      }
      @media (max-width: 992px) {
        flex-direction: column;
        .step-block {
          img {
            width: 50%;
          }
          &__info {
            width: 50%;
          }
        }
      }
      @media (max-width: 679px) {
        .step-block {
          img {
            width: 60%;
          }
          &__info {
            width: 60%;
          }
        }
      }
      @media (max-width: 590px) {
        .step-block {
          img {
            width: 70%;
          }
          &__info {
            width: 70%;
          }
        }
      }
      @media (max-width: 495px) {
        .step-block {
          img {
            width: 90%;
          }
          &__info {
            width: 90%;
          }
        }
      }
      @media (max-width: 400px) {
        .step-block {
          img {
            width: 100%;
          }
          &__info {
            width: 100%;
          }
        }
      }
    }
  }
  @include pad {
    h2 {
      font-size: 1.75rem;
    }
    h3 {
      font-size: 1.3rem;
    }
    .banner {
      height: 320px;
      .topic-container {
        padding: 32px 50px;
      }
    }
    section {
      .intro-block.dm-large {
        display: none;
      }
      .intro-block.dm-sm {
        display: block;
      }
      .step-block-container {
        .step-block {
          max-width: 245px;
          height: 245px;
          &__sub-info {
            font-size: 14px;
          }
          &::before {
            font-size: 270px;
            line-height: 245px;
          }
        }
      }
    }
  }
  @include mobile {
    h2 {
      font-size: 18px;
    }
    h3 {
      font-size: 1rem;
    }
    .custom-btn {
      font-size: 1rem;
    }
    .logo-container {
      height: 40px;
      padding: 10px;
      p {
        width: 2rem;
      }
      img {
        height: 100%;
      }
    }
    .banner {
      height: 180px;
      background-size: cover;
      background-position-x: center;
      .topic-container {
        width: 55%;
        padding: 1rem 0.5rem;
        padding-left: 0;
        .main-title {
          padding: 0.25rem 0.5rem;
        }
        h2 {
          padding-right: 0.5rem;
          padding-left: 0;
        }
        p {
          padding-left: 0;
          padding-right: 0.5rem;
          font-size: 12px;
        }
      }
    }
    section {
      padding: 1rem 0;
      .intro-block-container {
        padding: 0;
      }
      .intro-block,
      .intro-block.dm-sm {
        margin-right: 15px;
        padding: 0 10px;
        h4 {
          margin-bottom: 0.5rem;
        }
      }
      .intro-block.dm-sm > p {
        line-height: 3;
      }
      .step-block-container {
        width: 100%;
        max-width: unset;
        font-size: 1rem;
        margin: 0;
        padding: 0;
        .step-block {
          width: 100%;
          max-width: unset;
          height: 200px;
          margin: 0;
          margin-left: 0;
          margin-bottom: 8px;
          &__sub-info {
            font-size: 14px;
          }
          &::before {
            font-size: 225px;
            line-height: 200px;
          }
        }
      }
      table {
        th,
        td {
          padding: 0.5rem;
          border: 1px solid #333;
          min-width: unset;
        }
      }
    }
  }
}
