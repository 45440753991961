.account-page {
  padding: 24px 32px;
  background: $subLightColor;
  display: grid;
  gap: 36px;
  grid-template-areas:
    'class info'
    'point info'
    'card info'
    'pw totp';
  justify-items: center;
  align-items: flex-start;
  justify-content: center;
  &__container {
    width: 430px;
    padding: 10px 30px;
    border-radius: 8px;
    background-color: white;
    .upload-business-card {
      width: 100%;
      .preview-img-container {
        width: 80%;
        height: 200px;
        border: 1px solid #ccc;
        .preview-hint-text {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          color: gray;
          font-size: 1.2rem;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      .upload-btn {
        width: 60%;
        margin-top: 30px;
        .MuiButton-root {
          @include customButton;
          padding: 5px 10px;
          font-size: 1rem;
          width: 100%;
          margin: 0 auto;
        }
      }
    }
    .edit-data-accept {
      width: 60%;
      margin: 0 auto;
      margin-top: 30px;
      .edit-data-btn {
        @include customButton;
        padding: 5px 10px;
        font-size: 1rem;
        width: 100%;
        margin: 0 auto;
      }
    }
    .fieldset {
      margin-left: auto;
      margin-right: auto;
      padding: 10px 0;
      & + .fieldset {
        margin-top: 20px;
      }
      .form-control {
        width: unset;
      }
      .form-group {
        margin-bottom: 0;
      }
      .text-field-hint {
        color: gray;
        margin-bottom: 16px;
        display: flex;
        align-items: center;
      }
    }
    .title {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 20px;
      &::before {
        width: 10px;
        height: 20px;
        background-color: $mainColor;
        margin-right: 5px;
        content: '';
      }
    }
    .handler-btn {
      @include customButton;
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 4px;
      width: unset;
      margin: unset;
      margin-left: auto;
      font-weight: normal;
      font-size: 1rem;
      padding: 8px 16px;
      text-align: center;
      border-radius: 5px;
      background-color: transparent;
      border-color: $mainColor;
      color: $mainColor;
      box-shadow: unset;
      &:hover,
      &--active {
        background-color: lighten($mainColor, 20%);
        color: #fff;
        border-color: transparent;
      }
    }
    .update-pwd-btn {
      @include customButton;
      width: 60%;
      padding: 5px;
      font-size: 1rem;
      margin-top: 1rem;
    }
    .fill-hint {
      margin-bottom: 10px;
      color: red;
    }
    .properties {
      .property {
        .property-value {
          flex: 1;
        }
      }
    }
  }
  @include UltraHD {
    grid-template-areas:
      'class info card'
      'point totp pw';
  }
  @include laptop {
    grid-template-areas:
      'class'
      'point'
      'info'
      'card'
      'pw'
      'totp';
  }
  @include pad {
    & {
      padding: 16px 0;
    }
    &__container {
      width: calc(100vw - 240px);
      padding: 0 16px 8px;
    }
  }
  @include mobile {
    .properties {
      padding-left: 4px;
      row-gap: 16px;
      .property {
        flex-wrap: wrap;
        row-gap: 8px;
        .property-name {
          width: 100%;
        }
      }
    }
    &__container {
      width: calc(100vw - 70px);
      .upload-business-card {
        .preview-img-container {
          width: 100%;
        }
      }
    }
  }
  @include mobileXS {
  }
}
.switch-btn {
  width: 100%;
  text-align: right;
  margin-bottom: 20px;
  margin-left: auto;
  .MuiFormControlLabel-root {
    flex-direction: row-reverse;
    .MuiSwitch-colorSecondary.Mui-checked {
      color: $mainColor;
    }
    .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
      background-color: lighten($mainColor, 20%);
    }
    .MuiSwitch-colorSecondary.Mui-checked:hover {
      background-color: rgba(95, 67, 152, 0.1);
    }
  }
}
.apply-trial-point-dialog {
  .preview-img-container {
    width: calc(100% - 180px);
    height: 150px;
    border: 1px solid #ccc;
    .preview-hint-text {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: gray;
      font-size: 1.2rem;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .modal-footer {
    border-top: 1px solid #bbb;
    button {
      min-width: 100px;
    }
  }
}
