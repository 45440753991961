.storage-management {
  h2 {
    font-size: 2rem;
    text-align: center;
    font-weight: bold;
    padding-bottom: 16px;
  }
  .tab-container {
    display: flex;
    justify-content: center;
    margin: 1.5rem 0;
    border-bottom: 1px solid #ccc;
    .tab {
      width: 20%;
      text-align: center;
      padding-bottom: 10px;
      cursor: pointer;
      position: relative;
      &::after {
        content: '';
        width: 0;
        height: 5px;
        background-color: lighten($mainColor, 20%);
        position: absolute;
        bottom: -2.5px;
        left: 0;
        transition: all 0.2s ease-in-out;
      }
      &:hover {
        color: $mainColor;
        &::after {
          content: '';
          width: 100%;
          color: lighten($mainColor, 20%);
        }
      }
      &--active {
        color: $mainColor;
        font-weight: bold;
        &::after {
          content: '';
          width: 100%;
          background-color: $mainColor;
        }
      }
    }
  }
  .folder-container {
    width: 100%;
    .img-container {
      width: 100%;
      .img-block {
        width: calc(25% - 12px);
        height: 180px;
      }
    }
  }
}
