.news-center {
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 60px;
    margin-bottom: 30px;
    h2 {
      width: 300px;
      text-align: center;
      font-weight: bold;
      color: #333333;
      font-size: 1.5rem;

      padding: 10px;
      border-radius: 5px;
      color: #fff;
      background-color: $mainColor;
    }
    .decorate-line {
      width: calc((100% - 300px) / 2);
      height: 3px;
      background-color: $mainColor;
    }
  }
  .news-carousel {
    width: 100%;
  }
  nav.news-type {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 30px 0;
    border-bottom: 1px solid rgba(51, 51, 51, 0.4);
    .nav-link {
      width: 20%;
      text-align: center;
      padding-bottom: 10px;
      cursor: pointer;
      position: relative;
      &::after {
        content: '';
        width: 0;
        height: 5px;
        background-color: lighten($mainColor, 20%);
        position: absolute;
        bottom: -2.5px;
        left: 0;
        transition: all 0.2s ease-in-out;
      }
      &:hover {
        color: $mainColor;
        &::after {
          content: '';
          width: 100%;
          color: lighten($mainColor, 20%);
        }
      }
      &--active {
        color: $mainColor;
        font-weight: bold;
        &::after {
          content: '';
          width: 100%;
          background-color: $mainColor;
        }
      }
    }
  }
  .spinner-container {
    padding: 100px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .spinner-grow {
      width: 50px;
      height: 50px;
      background-color: $mainColor;
    }
    p {
      color: $mainColor;
      text-align: center;
      font-weight: bold;
      margin-left: 20px;
    }
  }
  @include mobile {
    nav.news-type {
      .nav-link {
        padding: 0.5rem;
      }
    }
  }
}
