.front-footer {
  width: 100%;
  background-color: darken($mainColor, 20%);
  color: #fff;
  padding-top: 30px;
  padding-bottom: 10px;
  .footer-icon {
    width: 30px;
    height: 30px;
    background-color: #fff;
    border-radius: 50%;
    padding: 4px;
    fill: $mainColor;
    user-select: none;
    cursor: pointer;
    margin-right: 1rem;
    transition: 0.3s;
    &:hover {
      box-shadow: 3px 3px 5px darken($mainColor, 40%);
      fill: darken($mainColor, 20%);
    }
  }
  .disable-hover-effect {
    cursor: unset;
    &:hover {
      box-shadow: unset;
      fill: $mainColor;
    }
  }
  .policy-rules {
    height: 30px;
    line-height: 1.75;
    margin-top: 2rem;
    &__link {
      text-decoration: none;
      color: #fff;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .brand-intro {
    letter-spacing: 0.5px;
    &__title {
      font-size: 1.2rem;
      font-weight: 900;
    }
    &__subtitle {
      font-size: 1.2rem;
      font-weight: 700;
      margin-top: 5px;
    }
    &__content {
      line-height: 1.25;
      margin: 1rem 0;
    }
  }
  .web-guide {
    letter-spacing: 0.5px;
    .container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    &__topic {
      width: 100%;
      font-weight: 700;
      margin: 1rem 0;
      background-color: $mainColor;
      padding: 5px 0;
      text-align: center;
    }
    &__item {
      display: block;
      margin-bottom: 10px;
      color: #fff;
      &:hover {
        font-weight: 400;
      }
    }
  }
  .company-info {
    padding: 0 15px;
    margin-top: 4rem;
    &__name {
      font-weight: 700;
      font-size: 1.2rem;
      margin-bottom: 1rem;
    }
    .tel {
      font-size: 1.1rem;
      letter-spacing: 0.5px;
    }
    .tel-num {
      margin-right: 15px;
    }
    &--bottom {
      display: none;
    }
  }
  .contact-form {
    padding-right: 0;
    .MuiTypography-h5 {
      font-weight: 700;
    }
    .MuiInputBase-input {
      color: #fff;
    }
    label,
    svg {
      color: #d5beff;
    }
    &:hover .MuiInput-underline::before {
      border-color: #d5beff;
    }
    .MuiInput-underline {
      &::before {
        border-color: #d5beff;
      }
      &::after {
        border-color: #fff;
      }
    }
    legend.MuiFormLabel-root {
      font-size: 0.8rem;
      color: #d5beff;
    }
    .MuiFormGroup-root {
      label {
        color: #fff;
      }
      .MuiRadio-colorSecondary {
        color: #d5beff;
      }
    }
    .MuiIconButton-label {
      color: #fff;
    }
    .MuiFormHelperText-root {
      color: red;
    }
    .loading-btn {
      @include customButton;
      width: 80%;
      font-size: 1rem;
      padding: 5px 0;
      color: #fff;
      background-color: $mainColor;
    }
  }
  @include pad {
    .policy-rules {
      height: unset;
      line-height: 1.5;
      font-size: 12px;
      padding: 0;
    }
  }
  @include mobile {
    padding-left: 15px;
    .footer-icon {
      margin-right: 10px;
    }
    .company-info {
      padding: 0;
      .tel {
        font-size: 1rem;
      }
      .company-address {
        font-size: 0.8rem;
      }
      &--top {
        display: none;
      }
      &--bottom {
        display: block;
      }
    }
    .contact-form {
      padding-left: 0;
      padding-right: 15px;
      margin: 30px 0;
    }
  }
  @include mobileXS {
    .contact-info {
      &__content {
        flex-wrap: wrap;
      }
    }
    .company-info {
      .company-address {
        font-size: 0.9rem;
      }
      .tel {
        width: 100%;
        margin-bottom: 15px;
        .tel-num {
          margin-right: 0;
        }
      }
    }
  }
}
