.page-controller {
  padding-bottom: 60px;
  text-align: center;
  font-family: 'Roboto';
  .pagination {
    justify-content: center;
    .page-item {
      width: 40px;
      height: 40px;
      margin: 0 8px;
      user-select: none;
    }
    .page-link {
      width: 100%;
      height: 100%;
      line-height: 40px;
      color: #333333;
      padding: 0;
      border-color: transparent;
      border-radius: 50%;
      font-weight: bold;
      &:focus {
        box-shadow: none;
      }
    }
    .page-item.active .page-link {
      color: #fff;
      background-color: #6d72e7;
    }
    .page-item.disabled .page-link {
      color: #ccc;
    }
  }
}
