.formContainer {
  background: rgb(64, 144, 224);
  height: calc(90vh);
  @extend .mainContainer;
  .formBody {
    @extend .mainBody;
    display: flex;
    padding: 2% 0;
    .formText {
      @include flex_func(flex-start, flex-start, column);
      width: 50%;
      margin-right: 10%;
      color: white;
      p {
        margin: 5% 0;
      }
    }
    .formMiddle {
      width: 40%;
      height: 100%;
      form {
        padding: 5%;
        background-color: white;
        border-radius: 2%;
        border: 1px solid transparent;
      }
      // input[type="checkbox"]{
      //   background: yellow;
      //   width: 50%;
      // }
      .custom-checkbox {
        margin: 0;
        width: 50%;
      }
      button {
        width: 100%;
      }
    }
  }
}
