.device-binding {
  tbody {
    td {
      text-align: unset;
    }
  }
}
.transfer-container {
  div.topic {
    padding-left: 10px;
    border-left: 10px solid $mainColor;
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 1rem;
  }
  .fieldset {
    display: flex;
    .title {
      margin-right: 30px;
      font-weight: bold;
    }
  }
  .transfer-btn {
    @include customButton;
    width: 100%;
    margin-top: 20px;
    padding: 5px;
  }
  .transfer-btn.Mui-disabled {
    background-color: #ccc;
    border-color: #bbb;
    box-shadow: 3px 3px 0 #bbb;
  }
  .point-display,
  .device-point-transfer {
    width: 50%;
    padding: 0 15px;
  }
  @include mobile {
    flex-direction: column-reverse;
    .point-display,
    .device-point-transfer {
      width: 100%;
      padding: 0;
      margin-bottom: 30px;
    }
  }
}
.modal-body.custom-table-list-dialog {
  .MuiTableRow-root.MuiTableRow-head {
    th {
      background-color: $subLightColor;
    }
  }
  .MuiPaper-root.MuiPaper-rounded {
    box-shadow: unset;
  }
}
