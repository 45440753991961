.unauthorized-page {
  text-align: center;
  padding: 60px 0;
  .content {
    h2 {
      font-size: 8rem;
      font-weight: bold;
    }
    p {
      margin: 1rem 0;
      font-size: 1.5rem;
    }
    svg {
      width: 6rem;
      height: 6rem;
    }
    button {
      @include customButton;
    }
  }
}
