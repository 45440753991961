.article-card-container {
  width: 100%;
  padding: 30px 60px;
  .article-card {
    width: 100%;
    height: 200px;
    border: 1px solid #333333;
    box-shadow: 5px 5px 0 darken($subLightColor, 10%);
    margin-bottom: 60px;
    padding: 10px;
    position: relative;
    border-radius: 5px;
    background-color: lighten($subLightColor, 5%);
    .article-type {
      width: 100px;
      padding: 10px;
      color: #fff;
      font-weight: bold;
      font-size: 1.2rem;
      position: absolute;
      left: -30px;
      top: -20px;
      border-radius: 5px;
      box-shadow: 2px 2px 0 #fff;
      text-align: center;
      &--red {
        background-color: #c11a1a;
      }
      &--orange {
        background-color: #da6506;
      }
      &--blue {
        background-color: #006eff;
      }
      &--darkGray {
        background-color: #333333;
      }
    }
    .article {
      width: 100%;
      height: 100%;
      display: flex;
      line-height: 1.2;
      &__img {
        width: 50%;
        height: 100%;
        img {
          width: calc(100% - 20px);
          height: 100%;
          object-fit: cover;
        }
      }
      &__textarea {
        width: 50%;
        padding: 20px;
        position: relative;
        .time {
          margin-bottom: 10px;
          color: #333333;
          padding-left: 5px;
          border-left: 5px solid lighten($mainColor, 20%);
        }
        .topic {
          margin-bottom: 10px;
          font-weight: bold;
          font-size: 1.2rem;
        }
        .content {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .btn {
          @include customButton;
          width: 200px;
          padding: 5px;
          position: absolute;
          bottom: 10px;
          right: 10px;
        }
      }
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  @include pad {
    padding: 30px;
  }
  @include mobile {
    padding: 10px;
    padding-top: 40px;
    .article-card {
      height: 250px;
      position: relative;
      .article-type {
        left: -10px;
      }
      .article {
        flex-direction: column;
        &__img {
          width: 100%;
          height: 80px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        &__textarea {
          width: 100%;
          padding: 10px;
          position: unset;
          .topic {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .btn {
            width: calc(100% - 20px);
          }
        }
      }
    }
  }
}
