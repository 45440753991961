.announcement-form {
  textarea {
    line-height: 20px;
  }
  .fieldset {
    margin-bottom: 1rem;
  }
  .tips-text {
    font-size: 0.8rem;
    color: red;
    line-height: 1.2;
    > p {
      margin: 5px 0;
    }
  }
  .upload-img-preview {
    width: 300px;
    height: 169px;
    border: 1px solid #aaa;
    .preview-text {
      text-align: center;
      color: gray;
      font-size: 1.2rem;
      line-height: 169px;
    }
    .preview-img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  @include mobile {
    .upload-img-preview {
      width: 100%;
    }
  }
}
