.signUpContainer {
  @extend .mainContainer;
  @extend .main-content;
  background: linear-gradient(45deg, #545ba8 40%, $mainColor 60%);
  .visibility-icon {
    position: absolute;
    top: 30%;
    right: 15px;
    user-select: none;
    cursor: pointer;
  }
  .visibility-off-icon {
    color: #a8a6a6;
  }
  .signUpMiddle {
    padding: 20px 40px;
    max-width: 550px;
    border-radius: 10px;
    background-color: white;
    margin: 0 10px;
    .signUpForm {
      .signUpLogo {
        @include flex_func(flex-end);
        img {
          width: 30%;
        }
      }
      .signUpTerms {
        width: 100%;
        font-size: 30px;
        @include flex_func(flex-start, center, row);
        img {
          width: 5%;
        }
        a {
          margin-left: 20px;
          text-decoration: none;
        }
      }
      .signUpBtn {
        @include flex_func();
      }
    }
  }
  .MuiButton-contained {
    width: 50%;
    background-color: $subPurple;
    &:hover {
      background-color: $mainColor;
    }
  }
  .signup-custom-btn {
    margin-left: 15px;
    background-color: lighten(#545ba8, 5%);
  }
  .MuiCheckbox-colorPrimary.Mui-checked {
    color: lighten(#545ba8, 5%);
  }
  .required-custom-label {
    font-size: 12px;
    color: red;
  }
  @include mobile {
    .contact-person-grid {
      flex-wrap: wrap;
      .signup-custom-btn {
        width: 100%;
        margin-left: 0;
        margin-top: 10px;
      }
    }
    .MuiButton-contained {
      width: 100%;
    }
  }
}

.modalWidth {
  width: 100vw;
}

.termTitle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 25px;
}
