.create-group {
  width: 100%;
  &__card {
    margin: 60px auto;
    background-color: #fff;
    padding: 20px 30px;
    border-radius: 10px;
    box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.2);
    &__title {
      font-size: 20px;
      color: $mainColor;
      margin-bottom: 20px;
    }
    .form-label {
      margin-bottom: 5px;
      color: $subColor;
    }
    &__submit-btn {
      width: 100%;
      background-color: $mainColor !important;
      border-radius: 5px;
      color: #fff !important;
    }
  }
}
