.custom-search-bar {
  width: 300px;
  border: 1px solid #333333;
  border-radius: 5px;
  padding: 5px 10px;
  box-shadow: 5px 5px 0 darken($subLightColor, 10%);
  background-color: #fff;
  margin-left: auto;
  margin-right: 60px;
  .MuiInputBase-root {
    width: calc(280px - 34px);
  }
  @include pad {
    margin-right: 30px;
  }
  @include mobile {
    width: calc(100% - 20px);
    margin: 0 auto;
    .MuiInputBase-root {
      width: calc(100% - 24px);
    }
  }
}
