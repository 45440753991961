$mainContainerWidth: 100vw;
$bodyContainerWidth: 60vw;
$menuPadding: 10vh;

// home scss
$titleMargin: 52px;
$sectionTitle: 4vw;

// $navHeight: 52px;
$navHeight: 50px;
$footerHeight: 32px;
$bannerHeight: calc(100vh - #{$navHeight});
$minMainContentHeight: calc(100vh - #{$navHeight} - #{$footerHeight});

// quotation.scss
$quotationWidth: 21cm;
$quotationHeight: 29.7cm;

$quotationTitle: 3rem;
$quotationSubtitle: 1rem;
$quotationFont: 0.8rem;

$quotationMainColor: #00a0e9;
$quotationSubColor: #2f3334;
$quotationLightColor: #f8feff;

// about.scss
$mainColor: #5a36a1;
$subPurple: #5d1d88;
$subColor: #424547;
$subLightColor: #f1f2fb;
$tabFontSize: 1.3rem;

$customBtnWidth: 300px;
